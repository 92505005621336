// @ts-check

import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "../contexts/AuthContext";
import "./App.css";
import ForgotPassword from "./ForgotPassword";
import Home from "./Home";
import Login from "./Login";
import MyStories from "./MyStories";
import PrivateRoute from "./PrivateRoute";
import Signup from "./Signup.js";
import StoryDetail from "./StoryDetail";
import StoryList from "./StoryList";
import StoryTelling from "./storytelling";
import Faqs from "./Faqs";
import UpdateProfile from "./UpdateProfile";
import About from "./About";
import Workshop from "./Workshop";
import Privacy from "./Privacy";

const App = () => {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path="/" component={Home} />
            <PrivateRoute path="/new-story" component={StoryTelling} />
            <PrivateRoute path="/update-profile" component={UpdateProfile} />
            <Route path="/signup" component={Signup} />
            <Route path="/login" component={Login} />
            <Route path="/about" component={About} />
            <Route path="/workshop" component={Workshop} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/herstories" component={StoryList} />
            <Route path="/faqs" component={Faqs} />
            <PrivateRoute path="/my-herstories" component={MyStories} />
            <Route path="/story/:id/edit" component={StoryTelling} />
            <Route path="/story/:id" component={StoryDetail} />
            <Route
              path="/affectlab"
              component={() => {
                window.location.href = "https://www.affectlab.org/";
                return null;
              }}
            />
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
};

export default App;
