// @ts-check

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { auth } from "../firebase";
import React, { useEffect, useState } from "react";
import * as ReactTranslated from "react-translated";
import translation from "./translation";
import LineIcon from "react-lineicons";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const SiteNav = ({ setLangPreference }) => {
  const { currentUser } = useAuth();
  const [navlanguage, setnavLangPreference] = useState("en");

  const [to, setTo] = useState("en");
  const storedlanguage = localStorage.getItem("language");

  function handleTranslate(e) {
    setTo(e.target.value);
    console.log("storedlanguage ", storedlanguage);

    if (e.target.value == "ar") {
      document.body.setAttribute("dir", "rtl");
    } else {
      document.body.removeAttribute("dir");
    }

    if (e.target.value !== "en") {
      localStorage.setItem("language", e.target.value);
      setLangPreference(e.target.value);
      setnavLangPreference(e.target.value);
    } else {
      setLangPreference("en");
      setnavLangPreference("en");
      localStorage.setItem("language", "en");
    }
    console.log("x language ", storedlanguage);
  }

  useEffect(() => {
    if (storedlanguage) {
      setTo(storedlanguage);
      console.log("stored language ", storedlanguage);
      setLangPreference(storedlanguage);
      setnavLangPreference(storedlanguage);

      if (storedlanguage == "ar") {
        document.body.setAttribute("dir", "rtl");
      } else {
        document.body.removeAttribute("dir");
      }
    }
  }, []);

  return (
    <ReactTranslated.Provider language={navlanguage} translation={translation}>
      <Navbar collapseOnSelect expand="lg">
        <Container fluid>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Brand href="/">Home</Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavLink to="/about" className="nav-link">
                <ReactTranslated.Translate text="About" />
              </NavLink>
              <NavLink to="/workshop" className="nav-link">
                <ReactTranslated.Translate text="Workshop" />
              </NavLink>
              <NavLink to="/herstories" className="nav-link">
                <ReactTranslated.Translate text="Herstory Samples" />
              </NavLink>
              <NavLink to="/faqs" className="nav-link">
                <ReactTranslated.Translate text="FAQs" />
              </NavLink>
            </Nav>
            <Nav className="subMenu">
              {currentUser ? (
                <>
                  <NavLink to="/my-herstories" className="nav-link">
                    <LineIcon name="book" size="xs" />{" "}
                    <ReactTranslated.Translate text="My Herstories" />
                  </NavLink>
                  <NavLink to="/update-profile" className="nav-link">
                    <LineIcon name="user" size="xs" />{" "}
                    <ReactTranslated.Translate text="Profile" />
                  </NavLink>
                  <button
                    className="nav-link"
                    onClick={() => {
                      auth.signOut();
                    }}
                  >
                    <LineIcon name="exit" size="xs" />{" "}
                    <ReactTranslated.Translate text="Logout" />
                  </button>
                </>
              ) : (
                <NavLink to="/login" className="nav-link">
                  <LineIcon name="enter" size="xs" />{" "}
                  <ReactTranslated.Translate text="Login" />
                </NavLink>
              )}
              <div className="languages">
                <LineIcon name="world" size="xs" />{" "}
                <select value={to} onChange={handleTranslate}>
                  <option value="en">English</option>
                  <option value="nl">Dutch</option>
                  <option value="ar">Arabic</option>
                </select>
              </div>
              <NavLink to="/new-story">
                <button className="btn" id="create">
                  <ReactTranslated.Translate text="Create" />
                </button>
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </ReactTranslated.Provider>
  );
};

export default SiteNav;
