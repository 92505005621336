// @ts-check

import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import LineIcon from "react-lineicons";
import { Accordion } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import * as ReactTranslated from "react-translated";
import { fetchStoriesByUserId } from "../actions";
import { useAuth } from "../contexts/AuthContext";
import { getEditStoryURL } from "../utils";
import DeleteStoryModal from "./delete-story-modal";
import Footer from "./footer";
import SiteNav from "./SiteNav";
import Spinner from "./spinner";
import { getCoverImageURL } from "./StoryDetail";
import translation from "./translation";
import Br from "./Br";
import TransAnchor from "./TransAnchor";
import About1 from "./About_page_1_1948.png";
import About2 from "./About_page_2_1949.png";
import About3 from "./About_page_3.png";

const Faqs = (props) => {
  const [language, setLangPreference] = useState("en");
  const history = useHistory();

  const { currentUser } = useAuth();
  const userId = currentUser?.uid;
  const voorkamerlink = "https://devoorkamer.org/";
  const contactlink = "mailto:hello@affectlab.org";

  return (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content"
    >
      <SiteNav setLangPreference={setLangPreference} />
      <Container className="Stories">
        <Row>
          <Accordion className="faqSection">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <ReactTranslated.Translate text="What is a Herstory?" />
              </Accordion.Header>
              <Accordion.Body>
                <ReactTranslated.Translate text="Herstories are narratives about women" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <ReactTranslated.Translate text="Can anyone write a Herstory?" />
              </Accordion.Header>
              <Accordion.Body>
                <ReactTranslated.Translate text="Yes, every storyteller is welcome" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <ReactTranslated.Translate text="Can I write a Herstory about myself?" />
              </Accordion.Header>
              <Accordion.Body>
                <ReactTranslated.Translate text="Yes. Herstories celebrate women" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <ReactTranslated.Translate text="Do I need photographs to write a Herstory?" />
              </Accordion.Header>
              <Accordion.Body>
                <ReactTranslated.Translate text="Our Herstory Tool uses photographs" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <ReactTranslated.Translate text="How do I share my Herstory with others?" />
              </Accordion.Header>
              <Accordion.Body>
                <ReactTranslated.Translate text="You can share your Herstory in three ways:" />
                <ol>
                  <li>
                    <ReactTranslated.Translate text="Share your Herstory publicly" />
                  </li>
                  <li>
                    <ReactTranslated.Translate text="Share your Herstory privately" />
                  </li>
                  <li>
                    <ReactTranslated.Translate text="Share your Herstory via social media" />
                  </li>
                </ol>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <ReactTranslated.Translate text="Do I have to share my Herstory?" />
              </Accordion.Header>
              <Accordion.Body>
                <ReactTranslated.Translate text="No, you can also save your Herstory as a draft or publish" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                <ReactTranslated.Translate text="How do I share my Herstory privately?" />
              </Accordion.Header>
              <Accordion.Body>
                <ReactTranslated.Translate text="When you save your draft or publish your Herstory privately" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                <ReactTranslated.Translate text="Are there any costs to use the Herstory tool?" />
              </Accordion.Header>
              <Accordion.Body>
                <ReactTranslated.Translate text="No, the tool is entirely free to use." />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                <ReactTranslated.Translate text="Are my photos stored privately?" />
              </Accordion.Header>
              <Accordion.Body>
                <ReactTranslated.Translate text="Yes. Only you have access to your photos on this site." />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                <ReactTranslated.Translate text="Where is this website hosted?" />
              </Accordion.Header>
              <Accordion.Body>
                <ReactTranslated.Translate text="The website is hosted on a cloud computing and storage platform" />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>
                <ReactTranslated.Translate text="What is the Herstory Tool trained on?" />
              </Accordion.Header>
              <Accordion.Body>
                <ReactTranslated.Translate text="The Herstory Tool includes a custom-developed machine learning model" />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
      </Container>
      <Footer />
    </ReactTranslated.Provider>
  );
};

export default Faqs;
