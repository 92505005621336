export const getDataURL = async (file) =>
  await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.readAsDataURL(file);
  });

export const rotateImage = async (file, rotation) => {
  // Get degrees between 0 (inclusive) and 360 (exclusive)
  let nextRotation = rotation;
  while (nextRotation < 0) {
    nextRotation += 360;
  }
  while (nextRotation >= 360) {
    nextRotation -= 360;
  }

  if (nextRotation === 0) {
    return getDataURL(file);
  } else {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();

    const nextDataURL = await new Promise((resolve) => {
      image.onload = () => {
        if (nextRotation === 180) {
          canvas.width = image.width;
          canvas.height = image.height;
        } else {
          canvas.width = image.height;
          canvas.height = image.width;
        }
        ctx.rotate((nextRotation * Math.PI) / 180);
        if (nextRotation === 90) {
          ctx.translate(0, -canvas.width);
        } else if (nextRotation === 180) {
          ctx.translate(-canvas.width, -canvas.height);
        } else if (nextRotation === 270) {
          ctx.translate(-canvas.height, 0);
        }
        ctx.drawImage(image, 0, 0);

        resolve(canvas.toDataURL(file.type));
        URL.revokeObjectURL(image.src);
      };

      image.src = URL.createObjectURL(file);
    });
    return nextDataURL;
  }
};

export const sortByIndex = (a, b) => {
  if (a.index < b.index) {
    return -1;
  } else {
    return 1;
  }
};

export const parseFirebaseDate = (firebaseDate) => {
  return new Date(firebaseDate.seconds * 1000);
};

export const sortSavedAt = (a, b) => {
  const aDate = parseFirebaseDate(a.savedAt);
  const bDate = parseFirebaseDate(b.savedAt);
  if (aDate > bDate) {
    return -1;
  } else {
    return 1;
  }
};

export const getEditStoryURL = (storyId) => `/story/${storyId}/edit`;
