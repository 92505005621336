// @ts-check

import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import LineIcon from "react-lineicons";
import { Link, useHistory } from "react-router-dom";
import * as ReactTranslated from "react-translated";
import { fetchStoriesByUserId } from "../actions";
import { useAuth } from "../contexts/AuthContext";
import { getEditStoryURL } from "../utils";
import DeleteStoryModal from "./delete-story-modal";
import Footer from "./footer";
import SiteNav from "./SiteNav";
import Spinner from "./spinner";
import { getCoverImageURL } from "./StoryDetail";
import translation from "./translation";
import Br from "./Br";
import TransAnchor from "./TransAnchor";
import About1 from "./About_page_1_1948.png";
import About2 from "./About_page_2_1949.png";
import About3 from "./About_page_3.png";

const Privacy = (props) => {
  const [language, setLangPreference] = useState("en");
  const history = useHistory();

  const { currentUser } = useAuth();
  const userId = currentUser?.uid;
  const voorkamerlink = "https://devoorkamer.org/";
  const contactlink = "mailto:hello@affectlab.org";

  return (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content"
    >
      <SiteNav setLangPreference={setLangPreference} />
      <Container className="Stories">
        <Row>
          <Col>
            <h2>Introduction</h2>
            <p>
              This privacy policy has been specifically written for the How to
              Tell a Herstory project, which has been designed and created by
              the affect lab foundation. Affect lab respects the privacy of its
              clients, collaborators and partners. We have specific regard for
              the participants of the Herstory project and want to make sure
              that the participants can exercise their rights in the best
              possible way.
            </p>
            <p>
              That’s why we have formulated and implemented this policy on
              complete transparency regarding the processing of personal data.
              In this policy, we will explain what data we process, why, how and
              explain how you can exercise your legal rights. If you have any
              questions regarding the data processing carried out by affect lab,
              you can contact us via{" "}
              <a href="mailto:hello@affectlab.org">hello@affectlab.org</a>.
            </p>
            <h2>Definitions</h2>
            <ul>
              <li>
                affect lab is responsible for collecting, saving, and using your
                personal data. As such, we are called a{" "}
                <strong>‘Controller’</strong>. The activities we carry out with
                regards to your personal information is called{" "}
                <strong>‘processing’</strong>.
              </li>
              <li>
                The details of the Controller are: affect lab; with registered
                address at Studio 0.74 on Wibautstraat 150, 1091GR Amsterdam, in
                The Netherlands. Our company registration number is 54507669.
                You can reach us via{" "}
                <a href="mailto:hello@affectlab.org">hello@affectlab.org</a>.
              </li>
              <li>
                When we say ‘<strong>Data Protection Authority’ </strong>we mean
                the national authority that is responsible for overseeing our
                compliance regarding data processing. This is the Data
                Protection Authority of the Netherlands (‘Autoriteit
                Persoonsgegevens’), who can be reached via their website on{" "}
                <a href="https://autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap">
                  https://autoriteitpersoonsgegevens.nl
                </a>
                .
              </li>
              <li>
                The <strong>Data Protection laws</strong> that are applicable to
                our processing activities. The rules in these laws are the rules
                we need to abide by to ensure that your information is kept safe
                and private.
              </li>
            </ul>

            <ul>
              <li>
                If you live in the EU or are a citizen of the EU, we need to
                abide by the EU GDPR 2018 and the EU e-privacy directive 2002.
                <ul>
                  <li>
                    If you live in the UK or are a citizen of the UK, we need to
                    abide by the UK GDPR 2020 and the UK Data Protection Act
                    2018.
                  </li>
                </ul>
                <ul>
                  <li>
                    For all other rules and regulations not directly related to
                    data processing, we must abide by the national laws of the
                    Netherlands because this is the country where we are
                    registered.
                  </li>
                </ul>
              </li>
            </ul>

            <ul>
              <li>
                Your personal data will be collected by affect lab and its
                partners, also known as ‘<strong>Data Processors</strong>’.
              </li>
              <li>
                When we say ‘<strong>Personal Data</strong>’ or{" "}
                <strong>‘Personal</strong> <strong>Information’</strong>, we
                mean any information that relates to an individual person. This
                person needs to be identifiable through the personal data.
                Sometimes, we will refer to these individuals as ‘
                <strong>Data Subjects</strong>’. An identifiable natural person
                is someone who can be identified, directly or indirectly, in
                particular by referring to an identifier like a name, an
                identification number, location data, an online identifier or to
                one or more factors specific to the physical, physiological,
                genetic, mental, economic, cultural or social identity of that
                person.
              </li>
            </ul>

            <h2>Information about our processing activities</h2>

            <p>
              <strong>Context of the How to Tell a Herstory project</strong>
            </p>

            <p>
              Through the Herstory project, we enable women with migration
              backgrounds to create stories using our Herstory application.
              These stories can be created during real-life workshops and
              privately, using the Herstory tool. Affect lab recognises the
              context of the participants, namely women from migration and
              asylum backgrounds, which may lead to a particular vulnerability.
              Affect lab takes this vulnerability into account in its data
              processing activities and endeavours to keep all data secure and
              private at all times. Affect lab does this in line with the
              international and regional laws related to privacy, as well as the
              national laws and regulations where applicable.
            </p>

            <p>
              <strong>How data is processed</strong>
            </p>

            <p>
              The participants create a profile using a name (which may be a
              pseudonym) and their email address. They then upload photos from
              their life into the application and are asked questions to
              describe the photos. The questions that are asked use a number of
              Machine Learning techniques (‘Proximity analysis’, ‘Sentiment
              Analysis’, ‘Object Detection’). The machine learning is conducted
              in the participant’s browser, their photos are not processed
              elsewhere. The application of machine learning enables the
              Herstory tools to ask specific questions related to the photo in
              question, making the experience more personal and appropriate.
            </p>

            <p>
              We also send past and future participants of the Herstory workshop
              and Herstory users an email with updates about Herstory and the
              other projects of affect lab. This is based on consent which is
              given at the moment of registration for a workshop or creating a
              profile on the Herstory tool. Individuals can withdraw their
              consent or opt-out by simply sending us an email at{" "}
              <a href="mailto:hello@affectlab.org">hello@affectlab.org</a>.
            </p>

            <p>
              <strong>Data sharing</strong>
            </p>

            <p>
              The application used for the Herstory project is built by us using
              a third party tool (Google Firebase), which is also used for
              authentication (proving the identity of the person trying to read
              information) and hosting the information which the participants
              enter into Herstory. The information collected by the Herstory
              tool is shared with this third party, under the strictest
              instructions of confidentiality and security.
            </p>

            <p>
              The data we collect from the participants is end-to-end encrypted,
              which means that nobody can read the data (on the server, nor in
              transit, nor on the device), unless the participants choose to
              make their story public. The participants themselves can choose to
              make the story visible to the public or not. Users of the Herstory
              tool and website can remain anonymous by using a pseudonym instead
              of their real name for their profile on the Herstory site. This
              decision does not affect the use or proper functioning of Herstory
              and women are encouraged to do so if there is any risk or
              perceived risk associated with sharing the story publicly.
            </p>

            <p>
              The participants are able to make use of social media to share
              their stories via the Herstory tool. They are at all times free to
              choose not to share their stories using social media.
            </p>

            <p>
              When women participate in the Herstory workshops, photos may be
              taken from the event. Sharing these images on social media is a
              large part of affect lab’s publishing activities. The participants
              are always asked whether they feel comfortable being visible and
              recognisable on social media. If a participant recognises
              themselves on one of affect lab’s social media channels and does
              not wish to be featured in a post, description or otherwise
              (anymore), the participant can make this known to the affect lab
              team and the image and/or description of the individual in
              question will be removed as soon as possible.
            </p>

            <p>
              Affect lab recognises that some of the workshop participants or
              users of the Herstory tool might be particularly vulnerable where
              they are in the process of seeking asylum for any type of
              persecution in their home country. Asylum seekers deserve
              particular protection and are therefore encouraged to think about
              setting their profile and story to private.
            </p>

            <p>
              Affect lab undertakes to prevent any immigration authorities from
              using the text, photos, stories or profiles to build a case
              against an asylum seeker by implementing strict security measures
              and data privacy.
            </p>

            <p>
              <strong>Data retention</strong>
            </p>

            <p>
              Affect lab retains Herstory data for 2 years after the profile has
              been created. The Herstory participants are contacted before the
              end of the 2 year time period to ask whether they want their
              profile and stories to be retained for future use or reference. If
              they do not reply, we destroy all data related to a single
              profile.
            </p>

            <p>
              <strong>Workshops under licence</strong>
            </p>

            <p>
              The Herstory workshop and website are licensed under Creative
              Commons Attribution-NonCommercial-ShareAlike 4.0 International (CC
              BY-NC-SA 4.0). This means that other organisations are allowed to
              host workshops without the involvement of affect lab under this
              licence. When this is the case, where the Herstory tool is used,
              affect lab remains responsible for the protection of the women’s
              data as it is entered into the affect lab tool. However, affect
              lab does not remain in control with regards to the workshop
              itself, the information shared during that workshop, images taken
              during the workshop or any other creative or proprietary
              information shared during or cultivated through the workshop in
              question. This is the responsibility of the organisation hosting
              the workshop and information on its data processing activities
              should be found in their Privacy Policy.
            </p>

            <p>
              <strong>Websites</strong>The Herstory website is built on React
              and runs on Firebase. With this website we provide you, our
              clients, partners and other interested individuals with
              information and give you the opportunity to contact us. In order
              to reply to your question or request, we have to process your
              personal information. You can send us emails via our website,
              which is sent through your own preferred email provider, such as
              Google or Microsoft. We also use cookies to provide visitors with
              a functional website and to analyse trends and profiles of our
              website visits. The information we process through our websites
              include your name, your email address and the information you send
              to us in your email. This is always based on your consent.
            </p>

            <p>
              <strong>Email and internal communication</strong>
            </p>

            <p>
              We use Google’s email service to provide you with information if
              you have requested us to do so, reply to your questions or
              requests, for administrative purposes, marketing, work planning
              and appointment scheduling. We use Signal for internal
              communications. Our contacts include customers, partners,
              suppliers, employees, contractors, government and finance
              providers. The information we collect or store through email
              include your name, your email address and the information you send
              to us in your email. This form of ‘data processing’ is based on
              your consent and our legitimate interest in pursuing our business
              purposes. The information we process through Signal is restricted
              to names. Information on Signal is end-to-end encrypted, which
              means that nobody outside the recipient can read the data (on the
              server, nor in transit, nor on the device).
            </p>

            <p>
              <strong>
                Storage of documents (business operations, administration and
                marketing)
              </strong>
            </p>

            <p>
              We store documents online in third party cloud applications. We
              use these cloud applications to make sure your data is kept safe
              and secure at all times. We use Google Drive (Gsuite), Sync,
              Notion and Founders for this. We do this for purposes of business
              and financial administration, management of our customers,
              partners, employees, contractors and suppliers, to carry out
              research after our workshops and other projects, marketing
              purposes, and carrying out our creative and research work. The
              information we store include the names and email addresses of our
              customers. It also includes contracts with contractors, suppliers,
              government agencies and financial institutions. We further store
              home addresses, contracts, copies of passports, bank account
              numbers, health information and CVs of our employees, interns and
              contractors. This form of data processing is based on our
              legitimate interests in pursuing our business purposes or carrying
              out our legal or contractual obligations.
            </p>

            <h2>Sharing data with third parties</h2>

            <p>
              We share your data with a few third-party service providers. We
              ensure that those companies respect the security of your data and
              to treat it in accordance with the law. By using third-party
              service providers, we may transfer your Personal Data outside of
              the Netherlands or the European Economic Area (EEA). When we do,
              you can expect the same degree of protection of your personal
              data. This is especially the case with:
            </p>

            <ul>
              <li>Squarespace, whose headquarters are located in the USA</li>
              <li>Notion, whose headquarters are located in the USA</li>
              <li>
                Google Firebase, whose headquarters are located in the USA
              </li>
              <li>Sync, whose headquarters are located in Canada</li>
            </ul>

            <p>
              Where affect lab uses third-party providers, we rely on contracts
              called processing agreements. In accordance with the law, these
              processing agreements include EU model clauses for international
              data transfers. We ensure that the third-party provider uses the
              following methods of protection, ensuring that your information is
              always kept safe and private:
            </p>

            <ul>
              <li>Encryption;</li>
              <li>Anonymisation;</li>
              <li>Pseudonymisation.</li>
            </ul>

            <h2>Protection of data</h2>

            <p>
              Your data is protected by affect lab and our partners. We have
              taken security measures to protect your data and require our
              partners to meet the same requirements. The following security
              measures are taken to protect your personal data:
            </p>

            <h2>Organisational security measures</h2>

            <h3>Staff</h3>

            <p>
              Affect lab staff members are required to act responsibly when it
              comes to data processing.&nbsp; They are asked to follow affect
              lab’s guidelines regarding confidentiality, business ethics,
              appropriate use and professional standards.All staff members sign
              a confidentiality agreement with affect lab to ensure that your
              data is kept private.&nbsp; We also train staff members on best
              security practices, including how to identify social hacks,
              phishing scams, and hackers to minimise the risk associated with
              outside threats.
            </p>

            <h3>Access controls</h3>

            <p>
              Affect lab maintains your data privacy by allowing only
              appropriate and authorised individuals access to information when
              it is critical to complete tasks for you. Affect lab staff members
              will not process customer data without this prior authorisation.
              Affect lab takes this very seriously, especially with respect to
              the vulnerability of our workshop participants and Herstory users.
              Affect lab does not allow access to any data by immigration
              authorities for the purpose of building a case against a refugee,
              asylum seeker or individuals with a migration background.
            </p>

            <h3>Data hosting</h3>

            <p>
              As a rule, data is hosted within the Netherlands, but it is
              possible that we might transfer personal data to countries within
              the EEA, to the UK or in exceptional circumstances outside of
              those areas. We ensure that we comply with the relevant data
              protection laws when sending data overseas. We rely on data
              processing agreements containing EU standard contractual clauses
              and take additional measures to secure this data transfer, such as
              anonymisation.
            </p>

            <h3>Physical security</h3>

            <p>
              The data centres in which personal data is stored are secured and
              monitored 24/7 and physical access to facilities is strictly
              limited to select staff.
            </p>

            <h2>Technical security measures</h2>

            <p>
              All devices which are used to access personal data for which we
              are responsible are secured with antivirus software, firewalls,
              encryption and access management. We regularly update operating
              systems and software to ensure vulnerabilities cannot be exploited
              by malicious outsiders.
            </p>

            <h2>Your rights regarding information</h2>

            <p>
              You always have the right to information, access, rectification,
              deletion and restriction of your personal data. You also have the
              right to object to the processing and the right to data
              portability, meaning that your information must be transferable to
              another media of your choosing.
            </p>

            <p>
              You can exercise these rights by contacting us at the following
              email address: <em>hello@affectlab.org</em>. We might ask you to
              provide a form of proof of identification to ensure that we are
              really talking to you.
            </p>

            <p>
              Within one month of your email, you will receive an answer from
              us. We will not charge you for submitting your request unless the
              request is ‘manifestly unfounded’ or otherwise unreasonable in its
              nature. Depending on the complexity and the number of the requests
              this period may be extended to two months.
            </p>

            <p>
              You may always file a complaint with the Dutch Data Protection
              Authority (‘Autoriteit Persoonsgegevens’) if you fear that your
              data may not be handled responsibly by us. You may do this by
              visiting the website of the Autoriteit Persoonsgegevens.
            </p>

            <h2>Data retention</h2>

            <p>
              Your information is used and retained for the duration determined
              by law. You may, at any time, request your data to be deleted from
              any affect lab account, system or other data processing medium by
              simply emailing us.
            </p>

            <h2>Applicable law</h2>

            <p>
              These conditions are governed by Dutch legislation. The court in
              Amsterdam has the sole jurisdiction if any dispute regarding these
              conditions may arise, save when a legal exception applies.
            </p>

            <h2>Contact</h2>

            <p>
              For questions, please contact: <em>hello@affectlab.org.</em>
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </ReactTranslated.Provider>
  );
};

export default Privacy;
