// @ts-check

import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import * as ReactTranslated from "react-translated";
import { fetchPublicPublishedStories } from "../actions";
import Footer from "./footer";
import SiteNav from "./SiteNav";
import Spinner from "./spinner";
import { getCoverImageURL } from "./StoryDetail";
import translation from "./translation";

const StoryList = (props) => {
  const [language, setLangPreference] = useState("en");
  const [stories, setStories] = useState(null);
  useEffect(() => {
    fetchPublicPublishedStories().then((data) => {
      setStories(data);
    });
  }, []);

  return (
    <ReactTranslated.Provider language={language} translation={translation}>
      <SiteNav setLangPreference={setLangPreference} />
      <div className="Stories">
        <div className="Storymeta">
          <h1>
            <ReactTranslated.Translate text="All Herstories" />
          </h1>
        </div>
        {stories ? (
          <Container className="Storiescontainer">
            <Row>
              {stories.length > 0 ? (
                stories.map((story) => {
                  const coverImageURL = getCoverImageURL(story);

                  return (
                    <Col sm="6" className="Storylist" key={story.id}>
                      <Link to={`/story/${story.id}`}>
                        {coverImageURL && (
                          <div
                            className="Storythumb"
                            style={{
                              backgroundImage: `url(${coverImageURL})`,
                            }}
                          />
                        )}
                        <div className="caption">
                          <h3>{story.title}</h3>
                          {story.excerpt && <p>{story.excerpt}</p>}
                          <span>- {story.userName}</span>
                        </div>
                      </Link>
                    </Col>
                  );
                })
              ) : (
                <Spinner />
              )}
            </Row>
          </Container>
        ) : (
          <Spinner />
        )}
      </div>
      <Footer />
    </ReactTranslated.Provider>
  );
};

export default StoryList;
