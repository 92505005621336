// @ts-check

import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ACTION, IMAGE } from ".";
import { rotateImage } from "../../utils";
import LineIcon from "react-lineicons";

const SortableImage = ({
  image,
  imageRefs,
  dispatch,
  dndActiveId,
  selectedCoverImageId,
  coverImageURL,
  coverImageId,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: image.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      style={{ ...style, cursor: "inherit" }}
    >
      <div className="uploadedImage">
        {dndActiveId !== image.id && (
          <>
            {image.dataURL && (
              <div>
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  src={image.dataURL}
                  alt="Uploaded by user"
                />
                {/* Hidden image for ML processing */}
                <img
                  src={image.dataURL}
                  ref={(el) => {
                    imageRefs.current[image.id] = el;
                  }}
                  style={{ display: "none" }}
                  alt="Uploaded by user"
                  onLoad={(e) => {
                    const imgEl = e.currentTarget;
                    dispatch({
                      type: ACTION.UPDATE_IMAGE,
                      payload: {
                        id: image.id,
                        naturalWidth: imgEl.naturalWidth,
                        naturalHeight: imgEl.naturalHeight,
                      },
                    });
                  }}
                />
                <div
                  {...listeners}
                  style={{ cursor: "grab" }}
                  className="reorderImage"
                ></div>
                <button
                  className="removeImage"
                  onClick={async () => {
                    // Set the image for deletion if it has been uploaded in the past (has a Firebase URL)
                    if (image.url) {
                      dispatch({
                        type: ACTION.ADD_IMAGE_ID_TO_DELETE,
                        payload: image.id,
                      });
                    }

                    // Handle the cover image being removed
                    if (image.id === selectedCoverImageId) {
                      if (coverImageURL) {
                        dispatch({
                          type: ACTION.ADD_IMAGE_ID_TO_DELETE,
                          payload: coverImageId,
                        });
                      }

                      dispatch({
                        type: ACTION.SET_COVER_IMAGE_ID_AND_BLOB,
                        payload: { id: null, blob: null },
                      });
                    }
                    dispatch({
                      type: ACTION.REMOVE_IMAGE_FROM_ACTIVE_CHAPTER,
                      payload: image.id,
                    });
                  }}
                  disabled={image.state === IMAGE.RUNNING_ML}
                >
                  <LineIcon name="close" size="xs" />
                </button>
              </div>
            )}

            <div className="imageActions">
              <button
                className="rotateImage imageAction"
                style={{ marginBottom: 16 }}
                onClick={async () => {
                  const nextRotation = image.rotation - 90;
                  const nextdataURL = await rotateImage(
                    image.file,
                    nextRotation
                  );

                  dispatch({
                    type: ACTION.UPDATE_IMAGE,
                    payload: {
                      id: image.id,
                      dataURL: nextdataURL,
                      rotation: nextRotation,
                    },
                  });
                }}
                title="Rotate image"
              >
                <LineIcon name="reload" size="xs" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SortableImage;
