// @ts-check

import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite";
import { deleteObject, ref } from "firebase/storage";
import { db, storage } from "./firebase";
import { sortSavedAt } from "./utils";

const STORIES_COLLECTION = "stories";

const getStoriesCollection = () => collection(db, STORIES_COLLECTION);

export const createStory = async (story) => {
  const storiesCollection = getStoriesCollection();
  const docRef = doc(storiesCollection, story.id);
  await setDoc(docRef, story);
};

export const deleteImageById = (id) => {
  const storageRef = ref(storage, `images/${id}`);
  return deleteObject(storageRef);
};

export const deleteAudioById = (id) => {
  const storageRef = ref(storage, `audio/${id}`);
  return deleteObject(storageRef);
};

export const deleteStory = async (id) => {
  const storiesCollection = getStoriesCollection();
  const q = query(storiesCollection, where("id", "==", id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (doc) => {
    await deleteDoc(doc.ref);
  });
};

export const fetchPublicPublishedStories = async () => {
  const storiesCollection = getStoriesCollection();
  const q = query(
    storiesCollection,
    where("isPublic", "==", true),
    where("isPublished", "==", true)
  );
  const querySnapshot = await getDocs(q);
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data.sort(sortSavedAt);
};

const USER_ID_KEY = "userId";

export const fetchStoriesByUserId = async (userId) => {
  const storiesCollection = getStoriesCollection();
  const q = query(storiesCollection, where(USER_ID_KEY, "==", userId));
  const querySnapshot = await getDocs(q);
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data.sort(sortSavedAt);
};

export const fetchStoryById = async (id) => {
  const storiesCollection = getStoriesCollection();
  const q = query(storiesCollection, where("id", "==", id));
  const querySnapshot = await getDocs(q);
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data?.[0];
};
