// @ts-check

import React, { useEffect, useState, useRef } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import LineIcon from "react-lineicons";
import { Link, useHistory } from "react-router-dom";
import * as ReactTranslated from "react-translated";
import translation from "./translation";
import { fetchStoriesByUserId } from "../actions";
import { useAuth } from "../contexts/AuthContext";
import { getEditStoryURL } from "../utils";
import DeleteStoryModal from "./delete-story-modal";
import Footer from "./footer";
import SiteNav from "./SiteNav";
import Spinner from "./spinner";
import { getCoverImageURL } from "./StoryDetail";
import Br from "./Br";
import TransAnchor from "./TransAnchor";
import Workshop1 from "./Workshop_page1.jpg";
import Workshop2 from "./Workshop_page2.jpg";
import Workshop3 from "./Workshop_page3.jpg";
import Workshop4 from "./Workshop_page4.jpg";
import Workshop5 from "./Workshop_18A0238.jpg";
import Workshop6 from "./Workshop_18A4761-min.jpg";
import Workshop7 from "./Workshop_18A5475-min.jpg";
import Workshop8 from "./Workshop_18A5549-min.jpg";
import emailjs from "@emailjs/browser";

const SERVICE_ID = "service_sxnj1s5";
const TEMPLATE_ID = "template_w0i9uwd";
const USER_ID = "user_hUkETcHJPXI0qO3eYvy7W";

const Workshop = (props) => {
  const [language, setLangPreference] = useState("en");
  const [validated, setValidated] = useState(false);
  const [emailSent, setemailSent] = useState(false);
  const history = useHistory();
  const [emailstatus, setEmailstatus] = useState(false);
  const [inputVal, setInputVal] = useState("");

  const { currentUser } = useAuth();
  const userId = currentUser?.uid;
  const form = useRef();

  const sendEmail = (e) => {
    const formx = e.currentTarget;
    if (formx.checkValidity() === false) {
      e.stopPropagation();
    }

    e.preventDefault();

    setValidated(true);

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID).then(
      (result) => {
        setemailSent(true);
        console.log("email ", result.text);
      },
      (error) => {
        console.log("email error ", error.text);
      }
    );
  };

  return (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content"
    >
      <SiteNav setLangPreference={setLangPreference} />
      <Container className="Stories">
        <Row>
          <Col>
            <h1>Workshop</h1>
          </Col>
        </Row>
        <Row className="article-section">
          <Col sm={6}>
            <p>
              <ReactTranslated.Translate text="How to Tell a Herstory workshops are powerful" />
            </p>
            <img src={Workshop2} className="article-img" />
            <div className="caption">
              <h5 className="photoCaption">
                <ReactTranslated.Translate text="Workshop at De Voorkamer" />
              </h5>
              <h6 className="photoCredit">
                <ReactTranslated.Translate text="Photo by Sana Mulay" />
              </h6>
            </div>
            {/* <h3>
              <ReactTranslated.Translate text="Forthcoming workshops" />
            </h3>
            <p>
              <ReactTranslated.Translate text="Saturday 14 May 2022" />
              <br></br>
              <ReactTranslated.Translate text="Saturday 21 May 2022" /> (
              <ReactTranslated.Translate text="fully booked" />)<br></br>
              <ReactTranslated.Translate text="Saturday 28 May 2022" /> (
              <ReactTranslated.Translate text="fully booked" />)<p></p>
              <ReactTranslated.Translate text="Every workshop is free and takes place at De Voorkamer, Kanaalstraat 225 Utrecht between 11:00 - 17:00." />
            </p> */}
            {/* {!emailSent ? (
              <Form
                onSubmit={sendEmail}
                ref={form}
                noValidate
                validated={validated}
              >
                <Form.Group
                  controlId="validationCustom01"
                  style={{ marginBottom: "32px" }}
                >
                  <Form.Label>
                    <ReactTranslated.Translate text="Full name" />
                  </Form.Label>
                  <Form.Control
                    required
                    id="form-input-control-last-name"
                    type="text"
                    placeholder="Name"
                    name="from_name"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please type your full name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationCustom02">
                  <Form.Label>
                    <ReactTranslated.Translate text="Email" />
                  </Form.Label>
                  <Form.Control
                    required
                    id="form-input-control-email"
                    type="email"
                    placeholder="name@example.com"
                    name="user_email"
                    onChange={(e) => setInputVal(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please type your email address.
                  </Form.Control.Feedback>
                </Form.Group>
                <button
                  type="submit"
                  disabled={!inputVal}
                  className="btn main-btn"
                  style={{ marginLeft: 0, marginTop: 16 }}
                >
                  <ReactTranslated.Translate text="Submit" />
                </button>
                <h6>
                  *<ReactTranslated.Translate text="By signing up" />.
                </h6>
              </Form>
            ) : (
              <div className="successful">Your Email has been sent!</div>
            )} */}
            {/* <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeZ6C6GKXWvo1W3nQj8KiHV8fXWN7Wf_g5mptzYjo0YYZKL0g/viewform"
              target="_blank"
            >
              <button className="btn main-btn" style={{ marginBottom: 32 }}>
                <ReactTranslated.Translate text="Sign up" />
              </button>
            </a> */}

            <img src={Workshop6} className="article-img" />
            <div className="caption">
              <h5 className="photoCaption">
                <ReactTranslated.Translate text="Workshop at De Voorkamer" />
              </h5>
              <h6 className="photoCredit">
                <ReactTranslated.Translate text="Photo by Sana Mulay" />
              </h6>
            </div>

            <img src={Workshop7} className="article-img" />
            <div className="caption">
              <h5 className="photoCaption">
                <ReactTranslated.Translate text="Workshop at De Voorkamer" />
              </h5>
              <h6 className="photoCredit">
                <ReactTranslated.Translate text="Photo by Sana Mulay" />
              </h6>
            </div>

            <img src={Workshop8} className="article-img" />
            <div className="caption">
              <h5 className="photoCaption">
                <ReactTranslated.Translate text="Workshop at De Voorkamer" />
              </h5>
              <h6 className="photoCredit">
                <ReactTranslated.Translate text="Photo by Sana Mulay" />
              </h6>
            </div>
          </Col>
          <Col sm={6}>
            <img src={Workshop3} className="article-img" />
            <div className="caption">
              <h5 className="photoCaption">
                <ReactTranslated.Translate text="Workshop at De Voorkamer" />
              </h5>
              <h6 className="photoCredit">
                <ReactTranslated.Translate text="Photo by Sana Mulay" />
              </h6>
            </div>
            <blockquote>
              “
              <ReactTranslated.Translate text="The questions that the tool asks" />
              ”
            </blockquote>
            <p className="quote-source">
              -{" "}
              <ReactTranslated.Translate text="Herstory workshop participant" />
            </p>

            <img src={Workshop1} className="article-img" />
            <div className="caption">
              <h5 className="photoCaption">
                <ReactTranslated.Translate text="Workshop at De Voorkamer" />
              </h5>
              <h6 className="photoCredit">
                <ReactTranslated.Translate text="Photo by Sana Mulay" />
              </h6>
            </div>
            <blockquote>
              “<ReactTranslated.Translate text="By looking for the answers" />”{" "}
            </blockquote>
            <p className="quote-source">
              -{" "}
              <ReactTranslated.Translate text="Herstory workshop participant" />
            </p>

            <img src={Workshop4} className="article-img" />
            <div className="caption">
              <h5 className="photoCaption">
                <ReactTranslated.Translate text="Workshop at De Voorkamer" />
              </h5>
              <h6 className="photoCredit">
                <ReactTranslated.Translate text="Photo by Sana Mulay" />
              </h6>
            </div>

            <img src={Workshop5} className="article-img" />
            <div className="caption">
              <h5 className="photoCaption">
                <ReactTranslated.Translate text="Workshop at De Voorkamer" />
              </h5>
              <h6 className="photoCredit">
                <ReactTranslated.Translate text="Photo by Sana Mulay" />
              </h6>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </ReactTranslated.Provider>
  );
};

export default Workshop;
