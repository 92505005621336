const Spinner = () => {
  return (
    <div className="loaderContainer">
      <div className="loader la-ball-grid-pulse la-1x la-dark">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Spinner;
