// @ts-check

import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { deleteAudioById, deleteImageById, deleteStory } from "../actions";
import * as ReactTranslated from "react-translated";
import translation from "./translation";
import SiteNav from "./SiteNav";

const DeleteStoryModal = ({ story, isOpen, close, afterDelete, props }) => {
  const [isDeleting, setDeleting] = useState(false);
  const [language, setLangPreference] = useState("en");

  if (!story) {
    return null;
  }

  return (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content"
    >
      <div style={{ display: "none" }}>
        <SiteNav setLangPreference={setLangPreference} />
      </div>
      <Modal show={isOpen} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>
              <ReactTranslated.Translate text="Delete story" />
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactTranslated.Translate text="Please confirm that you would like to delete" />{" "}
          <b>{story.title}</b>.{" "}
          <ReactTranslated.Translate text="This action is not reversible" />
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "flex" }}>
            <Button variant="secondary" onClick={close} disabled={isDeleting}>
              Cancel
            </Button>

            <Button
              variant="primary"
              disabled={isDeleting}
              onClick={async () => {
                setDeleting(true);

                const imageIds = story.chapters.flatMap((chapter) =>
                  chapter.images.map((image) => image.id)
                );

                const audioIds = story.chapters.flatMap((chapter) =>
                  chapter.images
                    .filter((image) => image.audioURL)
                    .map((image) => image.id)
                );

                await deleteStory(story.id);

                if (story.coverImageURL) {
                  console.log(
                    `Deleting cover image with id ${story.coverImageId}`
                  );
                  await deleteImageById(story.coverImageId).catch((e) => {
                    console.trace(e);
                  });
                }

                for (const imageId of imageIds) {
                  console.log(`Deleting image with id ${imageId}`);
                  await deleteImageById(imageId).catch((e) => {
                    console.trace(e);
                  });
                }
                for (const audioId of audioIds) {
                  console.log(`Deleting audio with id ${audioId}`);
                  await deleteAudioById(audioId).catch((e) => {
                    console.trace(e);
                  });
                }
                setDeleting(false);
                close();
                await afterDelete();
              }}
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </ReactTranslated.Provider>
  );
};

export default DeleteStoryModal;
