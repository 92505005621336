// @ts-check
import React, { useState } from "react";
import Footer from "./footer.js";
import Hero from "./hero.js";
import Intro from "./intro.js";
import SiteNav from "./SiteNav";
import CuratedStories from "./CuratedStories.js";
import * as ReactTranslated from "react-translated";
import translation from "./translation";

const Home = (props) => {
  const [language, setLangPreference] = useState("en");

  return (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content homePage"
    >
      <SiteNav setLangPreference={setLangPreference} />
      <Hero />
      <Intro lang={language} />
      <CuratedStories lang={language} />
      <Footer lang={language} />
    </ReactTranslated.Provider>
  );
};
export default Home;
