// @ts-check

import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { useEffect, useReducer, useRef, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ReactCrop from "react-image-crop";
import * as ReactTranslated from "react-translated";
import translation from "../translation";
import SiteNav from "../SiteNav";
import "react-image-crop/dist/ReactCrop.css";
import LineIcon from "react-lineicons";
import { ReactMediaRecorder } from "react-media-recorder";
import { ReactPictureAnnotation } from "react-picture-annotation";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory, useParams } from "react-router-dom";
import SwiperCore, {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";
import chatIcon from "./../../chatIcon.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import { v4 as uuidv4 } from "uuid";
import { createStory, deleteImageById, fetchStoryById } from "../../actions.js";
import { useAuth } from "../../contexts/AuthContext";
import { storage } from "../../firebase.js";
import { getDataURL, parseFirebaseDate, sortByIndex } from "../../utils.js";
import CustomAnnotation from "../Customannotation.js";
import Spinner from "../spinner.js";
import ReorderChapters from "./reorder-chapters.js";
import SelectImages, { getInitialImageState } from "./select-images.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const IMAGE_DETAILS_WIDTH = 400;
const IMAGE_REVIEW_WIDTH = 600;

const getInitialState = () => {
  return {
    id: uuidv4(),
    title: "",
    chapters: {},
    activeChapterId: null,
    activeImageId: null,
    isPublic: true,
    excerpt: "",
    selectedCoverImageId: null, // From the images in the chapters
    coverImageId: null, // A unique ID for the cropped cover image
    coverImageCrop: { unit: "%", width: 90, aspect: 16 / 9 },
    coverImageBlob: null,
    coverImageBlobUrl: null,
    coverImageURL: null,
    selectedAnnotationId: null,
    imageIdsToDelete: [],
  };
};

const getInitialChapter = () => {
  return {
    id: null,
    title: "",
    images: {},
    createdAt: new Date(),
    index: null,
  };
};

export const ACTION = {
  LOAD_SAVED_STORY: "LOAD_SAVED_STORY",
  UPDATE_STORY_TITLE: "UPDATE_STORY_TITLE",
  ADD_CHAPTER: "ADD_CHAPTER",
  REORDER_CHAPTERS: "REORDER_CHAPTERS",
  SET_ACTIVE_CHAPTER_ID: "SET_ACTIVE_CHAPTER_ID",
  UPDATE_ACTIVE_CHAPTER_TITLE: "UPDATE_ACTIVE_CHAPTER_TITLE",
  ADD_IMAGES_TO_ACTIVE_CHAPTER: "ADD_IMAGES_TO_ACTIVE_CHAPTER",
  REMOVE_IMAGE_FROM_ACTIVE_CHAPTER: "REMOVE_IMAGE_FROM_ACTIVE_CHAPTER",
  ADD_IMAGE_ID_TO_DELETE: "ADD_IMAGE_ID_TO_DELETE",
  REORDER_ACTIVE_CHAPTER_IMAGES: "REORDER_ACTIVE_CHAPTER_IMAGES",
  UPDATE_IMAGE: "UPDATE_IMAGE",
  SET_ACTIVE_IMAGE_ID: "SET_ACTIVE_IMAGE_ID",
  SET_STORY_PUBLIC: "SET_STORY_PUBLIC",
  SET_SELECTED_COVER_IMAGE_ID: "SET_SELECTED_COVER_IMAGE_ID", // Id of the image in the chapter
  SET_COVER_IMAGE_CROP: "SET_COVER_IMAGE_CROP",
  SET_COVER_IMAGE_ID_AND_BLOB: "SET_COVER_IMAGE_ID_AND_BLOB",
  SET_SELECTED_ANNOTATION_ID: "SET_SELECTED_ANNOTATION_ID",
  UPDATE_STORY_EXCERPT: "UPDATE_STORY_EXCERPT",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.LOAD_SAVED_STORY: {
      return action.payload;
    }
    case ACTION.UPDATE_STORY_TITLE: {
      return { ...state, title: action.payload };
    }
    case ACTION.ADD_CHAPTER: {
      return {
        ...state,
        chapters: { ...state.chapters, [action.payload.id]: action.payload },
      };
    }
    case ACTION.REORDER_CHAPTERS: {
      const nextChapters = state.chapters;
      for (const [key, value] of Object.entries(action.payload)) {
        nextChapters[key].index = value;
      }
      return {
        ...state,
        chapters: nextChapters,
      };
    }
    case ACTION.SET_ACTIVE_CHAPTER_ID: {
      return { ...state, activeChapterId: action.payload };
    }
    case ACTION.UPDATE_ACTIVE_CHAPTER_TITLE: {
      const chapterId = state.activeChapterId;
      return {
        ...state,
        chapters: {
          ...state.chapters,
          [chapterId]: {
            ...state.chapters[chapterId],
            title: action.payload,
          },
        },
      };
    }
    case ACTION.ADD_IMAGES_TO_ACTIVE_CHAPTER: {
      const chapterId = state.activeChapterId;
      return {
        ...state,
        chapters: {
          ...state.chapters,
          [chapterId]: {
            ...state.chapters[chapterId],
            images: {
              ...state.chapters[chapterId].images,
              ...action.payload,
            },
          },
        },
      };
    }
    case ACTION.REMOVE_IMAGE_FROM_ACTIVE_CHAPTER: {
      const chapterId = state.activeChapterId;
      const { [action.payload]: _, ...nextImages } =
        state.chapters[chapterId].images;
      return {
        ...state,
        chapters: {
          ...state.chapters,
          [chapterId]: {
            ...state.chapters[chapterId],
            images: nextImages,
          },
        },
      };
    }
    case ACTION.ADD_IMAGE_ID_TO_DELETE: {
      return {
        ...state,
        imageIdsToDelete: [...state.imageIdsToDelete, action.payload],
      };
    }
    case ACTION.REORDER_ACTIVE_CHAPTER_IMAGES: {
      const chapterId = state.activeChapterId;
      const nextImages = state.chapters[chapterId].images;
      for (const [key, value] of Object.entries(action.payload)) {
        nextImages[key].index = value;
      }
      return {
        ...state,
        chapters: {
          ...state.chapters,
          [chapterId]: {
            ...state.chapters[chapterId],
            images: nextImages,
          },
        },
      };
    }
    case ACTION.UPDATE_IMAGE: {
      const imageId = action.payload.id;
      const chapterId = Object.values(state.chapters).find((c) => {
        if (c.images[imageId]) {
          return true;
        }
        return false;
      }).id;
      return {
        ...state,
        chapters: {
          ...state.chapters,
          [chapterId]: {
            ...state.chapters[chapterId],
            images: {
              ...state.chapters[chapterId].images,
              [imageId]: {
                ...state.chapters[chapterId].images[imageId],
                ...action.payload,
              },
            },
          },
        },
      };
    }
    case ACTION.SET_ACTIVE_IMAGE_ID: {
      return { ...state, activeImageId: action.payload };
    }
    case ACTION.SET_STORY_PUBLIC: {
      return { ...state, isPublic: action.payload };
    }
    case ACTION.SET_SELECTED_COVER_IMAGE_ID: {
      return { ...state, selectedCoverImageId: action.payload };
    }
    case ACTION.SET_COVER_IMAGE_CROP: {
      return { ...state, coverImageCrop: action.payload };
    }
    case ACTION.SET_COVER_IMAGE_ID_AND_BLOB: {
      return {
        ...state,
        coverImageBlob: action.payload.blob,
        coverImageBlobUrl: action.payload.blobUrl,
        coverImageId: action.payload.id,
      };
    }
    case ACTION.SET_SELECTED_ANNOTATION_ID: {
      return { ...state, selectedAnnotationId: action.payload };
    }
    case ACTION.UPDATE_STORY_EXCERPT: {
      return { ...state, excerpt: action.payload };
    }

    default: {
      throw new Error(`Unexpected action: ${JSON.stringify(action)}`);
    }
  }
};

export const VIEW = {
  LOADING_SAVED_STORY: "LOADING_SAVED_STORY",
  STORY_TITLE: "STORY_TITLE",
  CHAPTER_TITLE: "CHAPTER_TITLE",
  SELECT_IMAGES: "SELECT_IMAGES",
  MODEL_DETECTING: "MODEL_DETECTING",
  IMAGE_DESCRIPTION: "IMAGE_DESCRIPTION",
  REVIEW: "REVIEW",
  REORDER_CHAPTERS: "REORDER_CHAPTERS",
  SELECT_COVER_IMAGE: "SELECT_COVER_IMAGE",
  CROP_COVER_IMAGE: "CROP_COVER_IMAGE",
  SAVING_STORY: "SAVING_STORY",
};

// Image states
export const IMAGE = {
  SELECTED: "SELECTED",
  RUNNING_ML: "RUNNING_ML",
  ML_COMPLETE: "ML_COMPLETE",
  DETAILED: "DETAILED",
  UPLOADING: "UPLOADING",
  UPLOADED: "UPLOADED",
};

const Storytelling = (props) => {
  const [language, setLangPreference] = useState("en");
  const isEdit = window.location.pathname.includes("/edit"); // Of a saved draft
  const [recordAudio, setrecordAudio] = useState(false);
  const [uploadAudio, setuploadAudio] = useState(false);
  const storySaved = () => toast("Herstory saved!");
  const saveButton = useRef(null);

  // @ts-ignore
  let { id } = useParams();

  useEffect(() => {
    // Pull story data if this is an edit of a saved story
    if (isEdit) {
      setView(VIEW.LOADING_SAVED_STORY);
      const loadStory = async () => {
        const initialState = getInitialState();

        const data = await fetchStoryById(id);
        console.log("edit data", data);

        const activeChapterId =
          data.chapters.sort(sortByIndex)?.[0]?.id ?? null;

        const nextChapters = {};
        for (const chapter of data.chapters) {
          const nextImages = {};
          for (const image of chapter.images) {
            const initialImageState = getInitialImageState();
            image.createdAt = parseFirebaseDate(image.createdAt);
            // Load images and audio into blobs and dataURL
            if (image.url) {
              const blob = await (await fetch(image.url)).blob();
              image.file = blob;

              // Load DataURL of this blob
              const dataURL = await getDataURL(image.file);
              image.dataURL = dataURL;
            }
            if (image.audioURL) {
              const blob = await (await fetch(image.audioURL)).blob();
              image.audioBlob = blob;
              image.audioBlobURL = URL.createObjectURL(blob);
              image.isRecordPaneOpen = true;
            }
            nextImages[image.id] = { ...initialImageState, ...image };
          }
          nextChapters[chapter.id] = { ...getInitialChapter, ...chapter };
          nextChapters[chapter.id].images = nextImages;
        }
        const nextState = data;
        console.log("nextState", nextState);
        nextState.chapters = nextChapters;

        nextState.activeChapterId = activeChapterId;

        dispatch({
          type: ACTION.LOAD_SAVED_STORY,
          payload: { ...initialState, ...nextState },
        });
        setView(VIEW.STORY_TITLE);
      };
      loadStory();
    }
  }, [id, isEdit]);

  /** @type [any, import("react").Dispatch<any>] */
  const [state, dispatch] = useReducer(reducer, getInitialState());
  // console.log(state);
  const [view, setView] = useState(VIEW.STORY_TITLE); // Start by asking the user to input a title for the story
  const [isCloseModalOpen, setCloseModalOpen] = useState(false);
  const croppingImageRef = useRef(null);

  const activeChapterImages = state.activeChapterId
    ? Object.values(state.chapters[state.activeChapterId].images).sort(
        sortByIndex
      )
    : [];

  const activeImage =
    state.activeChapterId && state.activeImageId
      ? state.chapters[state.activeChapterId].images[state.activeImageId]
      : null;

  const activeImageIndex = activeChapterImages.findIndex(
    (i) => i?.id === activeImage?.id
  );

  const nextImage = activeChapterImages[activeImageIndex + 1] ?? null;

  const sortedChapters = Object.values(state.chapters).sort(sortByIndex);
  const activeChapterIndex = sortedChapters.findIndex(
    (c) => c.id === state.activeChapterId
  );

  const isActiveChapterLastChapter = state.activeChapterId
    ? sortedChapters.findIndex((c) => c.id === state.activeChapterId) ===
      sortedChapters.length - 1
    : false;

  const addChapter = () => {
    const chapterId = uuidv4();
    dispatch({
      type: ACTION.ADD_CHAPTER,
      payload: {
        ...getInitialChapter(),
        ...{
          id: chapterId,
          index: sortedChapters.length,
        },
      },
    });
    dispatch({
      type: ACTION.SET_ACTIVE_CHAPTER_ID,
      payload: chapterId,
    });
    setView(VIEW.CHAPTER_TITLE);
  };

  const history = useHistory();

  const { currentUser } = useAuth();

  const sortedImages = sortedChapters.flatMap((chapter) =>
    Object.values(chapter.images)
      .sort(sortByIndex)
      .map((image) => image)
  );

  const saveStory = async (isPublished) => {
    setView(VIEW.SAVING_STORY);
    const userName = currentUser.displayName;
    const userId = currentUser.uid;
    const savedAt = new Date();

    const chapters = Object.values(state.chapters);

    const story = {
      id: state.id,
      userName,
      userId,
      title: state.title,
      savedAt,
      isPublic: state.isPublic,
      isPublished: isPublished,
      excerpt: state.excerpt,
      coverImageId: state.coverImageId,
      selectedCoverImageId: state.selectedCoverImageId,
      coverImageURL: null,
      chapters: chapters.map((c) => ({
        id: c.id,
        title: c.title,
        createdAt: c.createdAt,
        index: c.index,
        images: Object.values(c.images).map((i) => ({
          id: i.id,
          text: i.text,
          url: null,
          createdAt: i.createdAt,
          audioURL: null,
          annotationData: i.annotationData,
          naturalHeight: i.naturalHeight,
          naturalWidth: i.naturalWidth,
          index: i.index,
        })),
      })),
    };

    // Upload the cropped cover image
    if (state.coverImageBlob) {
      const storageRef = ref(storage, `images/${state.coverImageId}`);
      const coverImageUploadTask = uploadBytes(
        storageRef,
        state.coverImageBlob
      );
      console.log("uploading cover image", state.coverImageId);
      await coverImageUploadTask.then(
        async () => {
          const coverImageURL = await getDownloadURL(storageRef);
          story.coverImageURL = coverImageURL;
        },
        (error) => {
          console.error("Error uploading audio");
          console.trace(error);
        }
      );
    }

    // Upload images and associated audio data
    for (let x = 0; x < chapters.length; x++) {
      const chapter = chapters[x];
      const images = Object.values(chapter.images);
      for (let y = 0; y < images.length; y++) {
        const image = images[y];
        console.log("uploading", image.id, image.file);
        const blob = await (await fetch(image.dataURL)).blob(); // The rotation of the image is stored in the dataURL data
        const storageRef = ref(storage, `images/${image.id}`);
        const imageUploadTask = uploadBytesResumable(storageRef, blob, {
          contentType: image.file.type,
        });

        await new Promise((resolve) => {
          imageUploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              dispatch({
                type: ACTION.UPDATE_IMAGE,
                payload: { id: image.id, progress },
              });
            },
            (error) => {
              console.error("Error uploading image");
              console.trace(error);
            },
            async () => {
              const imageRef = ref(storage, `images/${image.id}`);

              const url = await getDownloadURL(imageRef);

              story.chapters[x].images[y].url = url;

              dispatch({
                type: ACTION.UPDATE_IMAGE,
                payload: {
                  id: image.id,
                  state: IMAGE.UPLOADED,
                  url,
                },
              });

              resolve(true);
            }
          );
        });

        if (image.audioBlob) {
          const storageRef = ref(storage, `audio/${image.id}`);
          const audioUploadTask = uploadBytes(storageRef, image.audioBlob);
          console.log("uploading audio", image.id);

          await audioUploadTask.then(
            async () => {
              const audioURL = await getDownloadURL(storageRef);
              story.chapters[x].images[y].audioURL = audioURL;
            },
            (error) => {
              console.error("Error uploading audio");
              console.trace(error);
            }
          );
        }
      }
    }

    // Existing images and audio are overwritten above. The story is overwritten below
    await createStory(story);

    // Delete any images that need deleting
    for (const imageId of state.imageIdsToDelete) {
      await deleteImageById(imageId).catch((e) => {
        console.trace(e);
      });
    }

    history.push(`/story/${story.id}`);
  };

  const autosaveStory = async (isPublished) => {
    const userName = currentUser.displayName;
    const userId = currentUser.uid;
    const savedAt = new Date();

    const chapters = Object.values(state.chapters);

    const story = {
      id: state.id,
      userName,
      userId,
      title: state.title,
      savedAt,
      isPublic: state.isPublic,
      isPublished: isPublished,
      excerpt: state.excerpt,
      coverImageId: state.coverImageId,
      selectedCoverImageId: state.selectedCoverImageId,
      coverImageURL: null,
      chapters: chapters.map((c) => ({
        id: c.id,
        title: c.title,
        createdAt: c.createdAt,
        index: c.index,
        images: Object.values(c.images).map((i) => ({
          id: i.id,
          text: i.text,
          url: null,
          createdAt: i.createdAt,
          audioURL: null,
          annotationData: i.annotationData,
          naturalHeight: i.naturalHeight,
          naturalWidth: i.naturalWidth,
          index: i.index,
        })),
      })),
    };

    // Upload the cropped cover image
    if (state.coverImageBlob) {
      const storageRef = ref(storage, `images/${state.coverImageId}`);
      const coverImageUploadTask = uploadBytes(
        storageRef,
        state.coverImageBlob
      );
      console.log("uploading cover image", state.coverImageId);
      await coverImageUploadTask.then(
        async () => {
          const coverImageURL = await getDownloadURL(storageRef);
          story.coverImageURL = coverImageURL;
        },
        (error) => {
          console.error("Error uploading audio");
          console.trace(error);
        }
      );
    }

    // Upload images and associated audio data
    for (let x = 0; x < chapters.length; x++) {
      const chapter = chapters[x];
      const images = Object.values(chapter.images);
      for (let y = 0; y < images.length; y++) {
        const image = images[y];
        console.log("uploading", image.id, image.file);
        const blob = await (await fetch(image.dataURL)).blob(); // The rotation of the image is stored in the dataURL data
        const storageRef = ref(storage, `images/${image.id}`);
        const imageUploadTask = uploadBytesResumable(storageRef, blob, {
          contentType: image.file.type,
        });

        await new Promise((resolve) => {
          imageUploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              dispatch({
                type: ACTION.UPDATE_IMAGE,
                payload: { id: image.id, progress },
              });
            },
            (error) => {
              console.error("Error uploading image");
              console.trace(error);
            },
            async () => {
              const imageRef = ref(storage, `images/${image.id}`);

              const url = await getDownloadURL(imageRef);

              story.chapters[x].images[y].url = url;

              dispatch({
                type: ACTION.UPDATE_IMAGE,
                payload: {
                  id: image.id,
                  state: IMAGE.UPLOADED,
                  url,
                },
              });

              resolve(true);
            }
          );
        });

        if (image.audioBlob) {
          const storageRef = ref(storage, `audio/${image.id}`);
          const audioUploadTask = uploadBytes(storageRef, image.audioBlob);
          console.log("uploading audio", image.id);

          await audioUploadTask.then(
            async () => {
              const audioURL = await getDownloadURL(storageRef);
              story.chapters[x].images[y].audioURL = audioURL;
            },
            (error) => {
              console.error("Error uploading audio");
              console.trace(error);
            }
          );
        }
      }
    }

    // Existing images and audio are overwritten above. The story is overwritten below
    await createStory(story);

    // Delete any images that need deleting
    for (const imageId of state.imageIdsToDelete) {
      await deleteImageById(imageId).catch((e) => {
        console.trace(e);
      });
    }
  };

  const Stepper = (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content"
    >
      <div style={{ display: "none" }}>
        <SiteNav setLangPreference={setLangPreference} />
      </div>
      <div className="stepper">
        <div className="stepWrapper">
          <div className="singleStep">
            <button
              className="stepButton"
              onClick={() => {
                dispatch({
                  type: ACTION.SET_ACTIVE_CHAPTER_ID,
                  payload: null,
                });
                dispatch({ type: ACTION.SET_ACTIVE_IMAGE_ID, payload: null });
                setView(VIEW.STORY_TITLE);
              }}
            >
              Edit story title
            </button>
          </div>
          {sortedChapters.map((c) => {
            const images = Object.values(c.images).sort(sortByIndex);
            return (
              <div
                className="editChapter singleStep"
                key={c.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <button
                  className="stepButton"
                  onClick={() => {
                    dispatch({
                      type: ACTION.SET_ACTIVE_CHAPTER_ID,
                      payload: c.id,
                    });
                    dispatch({
                      type: ACTION.SET_ACTIVE_IMAGE_ID,
                      payload: null,
                    });
                    setView(VIEW.CHAPTER_TITLE);
                  }}
                  style={{ width: "fit-content" }}
                >
                  Edit chapter title: {c.title}
                </button>
                <div className="editaddImages">
                  <button
                    className="stepButton"
                    onClick={() => {
                      dispatch({
                        type: ACTION.SET_ACTIVE_CHAPTER_ID,
                        payload: c.id,
                      });
                      dispatch({
                        type: ACTION.SET_ACTIVE_IMAGE_ID,
                        payload: null,
                      });
                      setView(VIEW.SELECT_IMAGES);
                    }}
                  >
                    Add/remove images
                  </button>
                  <div className="editImages">
                    {images.map((i) => {
                      return (
                        <button
                          key={i.id}
                          onClick={() => {
                            dispatch({
                              type: ACTION.SET_ACTIVE_CHAPTER_ID,
                              payload: c.id,
                            });
                            dispatch({
                              type: ACTION.SET_ACTIVE_IMAGE_ID,
                              payload: i.id,
                            });
                            setView(VIEW.IMAGE_DESCRIPTION);
                          }}
                        >
                          <img src={i.dataURL} />
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="finalStep">
          <button
            className="stepButton"
            onClick={() => {
              dispatch({
                type: ACTION.SET_ACTIVE_CHAPTER_ID,
                payload: null,
              });
              dispatch({ type: ACTION.SET_ACTIVE_IMAGE_ID, payload: null });
              setView(VIEW.REVIEW);
            }}
          >
            Review story
          </button>
          <button
            className="stepButton saveButton"
            onClick={async () => {
              await saveStory(false);
              history.push("/");
            }}
          >
            Save for later
          </button>
        </div>
      </div>
    </ReactTranslated.Provider>
  );

  const getView = () => {
    switch (view) {
      case VIEW.LOADING_SAVED_STORY: {
        return (
          <Card className="single-step">
            <Card.Body>
              <Container>
                <Spinner />
              </Container>
            </Card.Body>
          </Card>
        );
      }
      case VIEW.STORY_TITLE: {
        return (
          <ReactTranslated.Provider
            language={language}
            translation={translation}
            className="content"
          >
            <div style={{ display: "none" }}>
              <SiteNav setLangPreference={setLangPreference} />
            </div>
            <Card className="single-step">
              <Card.Body>
                <Container>
                  <div className="story-title">
                    <h1>
                      <ReactTranslated.Translate text="Create" />
                    </h1>
                    <p>
                      <ReactTranslated.Translate text="What is the title of your Herstory?" />
                    </p>
                    <input
                      placeholder="Title..."
                      value={state.title}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        dispatch({
                          type: ACTION.UPDATE_STORY_TITLE,
                          payload: value,
                        });
                      }}
                    />
                    <button
                      className="btn btn-block story-title-button btn-inv"
                      onClick={() => {
                        if (Object.values(state.chapters).length === 0) {
                          addChapter();
                        } else {
                          setView(VIEW.CHAPTER_TITLE);
                        }
                      }}
                      disabled={state.title === ""}
                    >
                      <ReactTranslated.Translate text="Next" />
                    </button>
                  </div>
                </Container>
              </Card.Body>
            </Card>
          </ReactTranslated.Provider>
        );
      }
      case VIEW.CHAPTER_TITLE: {
        const activeChapterTitle =
          state.chapters[state.activeChapterId]?.title || "";
        console.log("chapters ", Object.values(state.chapters).length);

        return (
          <ReactTranslated.Provider
            language={language}
            translation={translation}
            className="content"
          >
            <div style={{ display: "none" }}>
              <SiteNav setLangPreference={setLangPreference} />
            </div>
            <Card className="single-step">
              <Card.Body>
                <Container>
                  <div className="chapter-title">
                    <h1>
                      <ReactTranslated.Translate text="What is the title of this chapter?" />
                    </h1>
                    <p>
                      <ReactTranslated.Translate text="In your Herstory we ask" />
                    </p>
                    <input
                      placeholder="Title..."
                      value={activeChapterTitle}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        // Will update for the active chapter as per state
                        dispatch({
                          type: ACTION.UPDATE_ACTIVE_CHAPTER_TITLE,
                          payload: value,
                        });
                      }}
                    />
                    <button
                      className="btn btn-block chapter-title-button btn-inv storybackBtn"
                      onClick={() => {
                        if (activeChapterIndex === 0) {
                          setView(VIEW.STORY_TITLE);
                        } else {
                          const prevChapter =
                            sortedChapters[activeChapterIndex - 1];
                          const prevChapterId = prevChapter.id;
                          const prevChapterImages = Object.values(
                            prevChapter.images
                          ).sort(sortByIndex);
                          const prevChapterLastImageId =
                            prevChapterImages[prevChapterImages.length - 1].id;

                          dispatch({
                            type: ACTION.SET_ACTIVE_CHAPTER_ID,
                            payload: prevChapterId,
                          });
                          dispatch({
                            type: ACTION.SET_ACTIVE_IMAGE_ID,
                            payload: prevChapterLastImageId,
                          });
                          setView(VIEW.IMAGE_DESCRIPTION);
                        }
                      }}
                    >
                      <ReactTranslated.Translate text="Back" />
                    </button>
                    <button
                      className="btn btn-block chapter-title-button btn-inv"
                      onClick={() => {
                        setView(VIEW.SELECT_IMAGES);
                      }}
                      disabled={activeChapterTitle === ""}
                    >
                      <ReactTranslated.Translate text="Next" />
                    </button>
                  </div>
                </Container>
              </Card.Body>
            </Card>
          </ReactTranslated.Provider>
        );
      }
      case VIEW.SELECT_IMAGES: {
        return (
          <SelectImages
            activeChapterImages={activeChapterImages}
            setView={setView}
            dispatch={dispatch}
            selectedCoverImageId={state.selectedCoverImageId}
            coverImageURL={state.coverImageURL}
            coverImageId={state.coverImageId}
          />
        );
      }
      case VIEW.IMAGE_DESCRIPTION: {
        const activeImageHeight =
          (activeImage.naturalHeight / activeImage.naturalWidth) *
          IMAGE_DETAILS_WIDTH;

        const imageIndex = activeChapterImages.findIndex(
          (i) => i.id === activeImage.id
        );
        const isFirstImage = imageIndex === 0;

        // const MINUTE_MS = 60000;

        // const interval = setInterval(async () => {
        //   saveButton.current.click();
        // }, MINUTE_MS);

        return (
          <ReactTranslated.Provider
            language={language}
            translation={translation}
            className="content"
          >
            <div style={{ display: "none" }}>
              <SiteNav setLangPreference={setLangPreference} />
            </div>
            <div className="single-step" key={state.activeImageId}>
              <Container fluid className="step-body">
                <Row>
                  <Col sm={6}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          width: IMAGE_DETAILS_WIDTH,
                          height: activeImageHeight,
                        }}
                      >
                        {!activeImage.isAnnotationEnabled ? (
                          <img
                            src={activeImage.dataURL}
                            width={IMAGE_DETAILS_WIDTH}
                            alt="Detection"
                          />
                        ) : (
                          <ReactPictureAnnotation
                            scrollSpeed={0}
                            image={activeImage.dataURL}
                            annotationData={activeImage.annotationData}
                            selectedId={state.selectedAnnotationId}
                            onSelect={(id) => {
                              if (
                                id === null ||
                                id === state.selectedAnnotationId
                              ) {
                                dispatch({
                                  type: ACTION.SET_SELECTED_ANNOTATION_ID,
                                  payload: null,
                                });
                              } else {
                                dispatch({
                                  type: ACTION.SET_SELECTED_ANNOTATION_ID,
                                  payload: id,
                                });
                              }
                            }}
                            onChange={(data) => {
                              if (
                                data.length > activeImage.annotationData.length
                              ) {
                                const selectedAnnotationId =
                                  data[data.length - 1].id;

                                dispatch({
                                  type: ACTION.SET_SELECTED_ANNOTATION_ID,
                                  payload: selectedAnnotationId,
                                });
                              }

                              dispatch({
                                type: ACTION.UPDATE_IMAGE,
                                payload: {
                                  id: activeImage.id,
                                  annotationData: data,
                                },
                              });
                            }}
                            width={IMAGE_DETAILS_WIDTH}
                            height={activeImageHeight}
                            inputElement={(value, onChange, onDelete) => (
                              <CustomAnnotation
                                {...{ value, onChange, onDelete }}
                              />
                            )}
                            annotationStyle={{
                              padding: 5, // text padding
                              fontSize: 13, // text font size
                              fontColor: "#000000", // text font color
                              fontBackground: "#00d300", // text background color
                              fontFamily:
                                "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",

                              /** stroke style **/
                              lineWidth: 3, // stroke width
                              shapeBackground: "hsla(120, 100%, 41%, 0.12)", // background color in the middle of the marker
                              shapeStrokeStyle: "#00d300", // shape stroke color
                              shadowBlur: 10, // stroke shadow blur
                              shapeShadowStyle: "hsla(210, 9%, 31%, 0.35)", // shape shadow color

                              /** transformer style **/
                              transformerBackground: "#ffffff",
                              transformerSize: 10,
                            }}
                          />
                        )}
                      </div>

                      <div
                        style={{ width: IMAGE_DETAILS_WIDTH }}
                        className="image-actions"
                      >
                        <div className="Annotationbutton">
                          {!activeImage.isAnnotationEnabled ? (
                            <button
                              className="circular-btn icon-btn"
                              onClick={() => {
                                dispatch({
                                  type: ACTION.UPDATE_IMAGE,
                                  payload: {
                                    id: activeImage.id,
                                    isAnnotationEnabled: true,
                                  },
                                });
                              }}
                            >
                              <LineIcon name="bubble" />
                              <ReactTranslated.Translate text="Annotate image" />
                            </button>
                          ) : (
                            <p>
                              <ReactTranslated.Translate text="Click and drag on the image above to add a note" />
                            </p>
                          )}
                        </div>
                        <button
                          className="circular-btn"
                          onClick={() => {
                            const activeChapterIndex = sortedChapters.findIndex(
                              (c) => c.id === state.activeChapterId
                            );
                            const currentChapterId =
                              sortedChapters[activeChapterIndex].id;

                            dispatch({
                              type: ACTION.SET_ACTIVE_CHAPTER_ID,
                              payload: currentChapterId,
                            });
                            dispatch({
                              type: ACTION.SET_ACTIVE_IMAGE_ID,
                              payload: null,
                            });
                            setView(VIEW.SELECT_IMAGES);
                          }}
                        >
                          <LineIcon name="image" />
                          <ReactTranslated.Translate text="Add image" />
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col sm={5} className="writing">
                    <div className="writingPrompt">
                      <div className="writingSection">
                        <div className="promptSlider">
                          <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            navigation={{
                              prevEl: ".prev",
                              nextEl: ".next",
                            }}
                          >
                            <SwiperSlide data-hash="moredetail">
                              <p className="conversation">
                                <ReactTranslated.Translate text="Hi" />{" "}
                                {currentUser.displayName},{" "}
                                <ReactTranslated.Translate text="I'm going to help you tell your story" />
                              </p>
                            </SwiperSlide>

                            {activeImage.intimacyPerception && (
                              <SwiperSlide data-hash="intimacy">
                                <p className="conversation">
                                  {activeImage.intimacyPerception ==
                                    "close" && (
                                    <ReactTranslated.Translate text="close" />
                                  )}
                                  {activeImage.intimacyPerception ==
                                    "really close" && (
                                    <ReactTranslated.Translate text="very close" />
                                  )}
                                  {activeImage.intimacyPerception ==
                                    "distant" && (
                                    <ReactTranslated.Translate text="distant" />
                                  )}
                                  {activeImage.intimacyPerception ==
                                    "very distant" && (
                                    <ReactTranslated.Translate text="very distant" />
                                  )}
                                </p>
                              </SwiperSlide>
                            )}
                            {activeImage.nonHumanObject && (
                              <SwiperSlide data-hash="nonhuman">
                                <p className="conversation">
                                  <ReactTranslated.Translate text="What is in this photo?" />
                                </p>
                              </SwiperSlide>
                            )}

                            <SwiperSlide data-hash="moredetail">
                              <p className="conversation">
                                <ReactTranslated.Translate text="When and where was it taken?" />
                              </p>
                            </SwiperSlide>

                            {activeImage.nonHumanObject && (
                              <SwiperSlide data-hash="nonhuman">
                                <p className="conversation">
                                  <ReactTranslated.Translate text="I see a" />{" "}
                                  {activeImage.nonHumanObject}{" "}
                                  <ReactTranslated.Translate text="in this photo. Is there a special object you would like to write about?" />
                                </p>
                              </SwiperSlide>
                            )}

                            <SwiperSlide data-hash="moredetail">
                              <p className="conversation">
                                <ReactTranslated.Translate text="Did you notice anything unusual in this photo?" />
                              </p>
                            </SwiperSlide>

                            {activeImage.isCrowd && (
                              <SwiperSlide data-hash="gathering">
                                <p className="conversation">
                                  <ReactTranslated.Translate text="I see a lot of people in this photo." />
                                </p>
                              </SwiperSlide>
                            )}
                            {activeImage.moodPerception && (
                              <SwiperSlide data-hash="mood">
                                <p className="conversation">
                                  {activeImage.moodPerception == "happy" && (
                                    <ReactTranslated.Translate text="happy" />
                                  )}
                                  {activeImage.moodPerception == "serious" && (
                                    <ReactTranslated.Translate text="serious" />
                                  )}
                                </p>
                              </SwiperSlide>
                            )}
                            <SwiperSlide data-hash="moredetail">
                              <p className="conversation">
                                <ReactTranslated.Translate text="What do you imagine you could smell or hear in this photo?" />
                              </p>
                            </SwiperSlide>

                            <SwiperSlide data-hash="moredetail">
                              <p className="conversation">
                                <ReactTranslated.Translate text="Can you describe the feeling in this photo, was it warm or cold?" />
                              </p>
                            </SwiperSlide>

                            <SwiperSlide data-hash="final">
                              <p className="conversation">
                                <ReactTranslated.Translate text="Is there something or someone that reminds you of yourself in this photo?" />
                              </p>
                            </SwiperSlide>

                            <SwiperSlide data-hash="final">
                              <p className="conversation">
                                <ReactTranslated.Translate text="How do you feel when you see this photo?" />
                              </p>
                            </SwiperSlide>

                            <SwiperSlide data-hash="final">
                              <p className="conversation">
                                <ReactTranslated.Translate text="Is there any extra information you can add to this photo?" />
                              </p>
                            </SwiperSlide>
                          </Swiper>
                          <div className="Slidenavigation promptslide">
                            <div className="slidenav prev">
                              <LineIcon name="chevron-left" />
                            </div>
                            <div className="slidenav next">
                              <LineIcon name="chevron-right" />
                            </div>
                          </div>
                        </div>
                        <ReactQuill
                          theme="snow"
                          value={activeImage.text}
                          onChange={(content) => {
                            if (content !== "<p><br></p>") {
                              dispatch({
                                type: ACTION.UPDATE_IMAGE,
                                payload: { id: activeImage.id, text: content },
                              });
                            }
                          }}
                        />
                      </div>
                      {activeImage.audioBlobURL && (
                        <div className="audioPlayback">
                          <audio src={activeImage.audioBlobURL} controls />
                          <button
                            onClick={() => {
                              setrecordAudio(false);
                              setuploadAudio(false);
                              dispatch({
                                type: ACTION.UPDATE_IMAGE,
                                payload: {
                                  id: activeImage.id,
                                  audioBlob: null,
                                  audioBlobURL: null,
                                },
                              });
                            }}
                            className="circular-btn uploadaudio"
                          >
                            <LineIcon name="close" />
                          </button>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col sm={1} className="story-actions">
                    <ToastContainer
                      position="top-left"
                      autoClose={5000}
                      hideProgressBar={true}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />

                    <div className="main-actions">
                      <button
                        className="circular-btn"
                        onClick={async () => {
                          await autosaveStory(false);
                          storySaved();
                        }}
                        ref={saveButton}
                      >
                        <LineIcon name="save" />
                        <ReactTranslated.Translate text="Save" />
                      </button>

                      <button
                        className="circular-btn"
                        onClick={() => {
                          dispatch({
                            type: ACTION.UPDATE_IMAGE,
                            payload: {
                              id: activeImage.id,
                              isRecordPaneOpen: true,
                            },
                          });
                        }}
                      >
                        <LineIcon name="mic" />
                        <ReactTranslated.Translate text="Add audio" />
                      </button>
                      {activeImage.isRecordPaneOpen && (
                        <div className="recording">
                          {!uploadAudio && (
                            <ReactMediaRecorder
                              audio={true}
                              onStop={(blobUrl, blob) => {
                                console.log("blob ", blob);
                                dispatch({
                                  type: ACTION.UPDATE_IMAGE,
                                  payload: {
                                    id: activeImage.id,
                                    audioBlob: blob,
                                    audioBlobURL: blobUrl,
                                  },
                                });
                              }}
                              render={({
                                status,
                                startRecording,
                                stopRecording,
                              }) => (
                                <>
                                  <button
                                    onClick={() => {
                                      setrecordAudio(true);
                                      setuploadAudio(false);
                                      startRecording();
                                    }}
                                    className="circular-btn mediaBtn record"
                                  >
                                    <LineIcon name="microphone" /> Record
                                  </button>
                                  {status === "recording" && (
                                    <button
                                      onClick={stopRecording}
                                      className="circular-btn mediaBtn stop-recording"
                                    >
                                      <LineIcon name="stop" />{" "}
                                      <ReactTranslated.Translate text="Stop" />
                                    </button>
                                  )}
                                </>
                              )}
                            />
                          )}
                          {!uploadAudio && !recordAudio && (
                            <button
                              onClick={() => {
                                setrecordAudio(false);
                                setuploadAudio(true);
                              }}
                              className="circular-btn mediaBtn uploadaudio"
                            >
                              <LineIcon name="cloud-upload" />{" "}
                              <ReactTranslated.Translate text="Upload audio (mp3 / wav)" />
                            </button>
                          )}

                          {uploadAudio && !recordAudio && (
                            <>
                              <label
                                htmlFor="filePicker"
                                className="circular-btn"
                                style={{ cursor: "pointer" }}
                              >
                                <LineIcon
                                  name="cloud-upload"
                                  style={{
                                    borderColor: "blue",
                                    color: "blue",
                                  }}
                                />
                              </label>

                              <input
                                style={{ visibility: "hidden" }}
                                type="file"
                                className="uploadAudio"
                                accept=".wav,.mp3"
                                id="filePicker"
                                onChange={async (e) => {
                                  const file = e.target.files[0];
                                  var blob = new Blob([file], {
                                    type: "audio/mpeg",
                                  });
                                  var blobUrl = URL.createObjectURL(blob);
                                  console.log("blob ", blob);
                                  dispatch({
                                    type: ACTION.UPDATE_IMAGE,
                                    payload: {
                                      id: activeImage.id,
                                      audioBlob: blob,
                                      audioBlobURL: blobUrl,
                                    },
                                  });
                                }}
                              />
                            </>
                          )}
                        </div>
                      )}

                      {isActiveChapterLastChapter && (
                        <button
                          className="circular-btn btn-block"
                          onClick={() => {
                            addChapter();
                          }}
                        >
                          <LineIcon name="add-files" />
                          <ReactTranslated.Translate text="Add another chapter" />
                        </button>
                      )}
                    </div>

                    {nextImage && (
                      <button
                        className="circular-btn btn-block"
                        onClick={() => {
                          dispatch({
                            type: ACTION.UPDATE_IMAGE,
                            payload: {
                              id: activeImage.id,
                              state: IMAGE.DETAILED,
                            },
                          });
                          setView(VIEW.REVIEW);
                        }}
                      >
                        <LineIcon name="eye" />{" "}
                        <ReactTranslated.Translate text="Preview" />
                      </button>
                    )}
                  </Col>
                </Row>
                <div className="persistentActions">
                  <button
                    className="btn btn-block photo-button story-right storybackBtn"
                    onClick={() => {
                      if (isFirstImage) {
                        setView(VIEW.SELECT_IMAGES);
                      } else {
                        // Back to previous image
                        dispatch({
                          type: ACTION.SET_ACTIVE_IMAGE_ID,
                          payload: activeChapterImages[imageIndex - 1].id,
                        });
                      }
                    }}
                  >
                    <ReactTranslated.Translate text="Back" />
                  </button>
                  {nextImage ? (
                    <button
                      className="btn btn-block photo-button main-btn"
                      onClick={() => {
                        const activeImageId = activeImage.id;
                        const nextImageId = nextImage.id;
                        dispatch({
                          type: ACTION.UPDATE_IMAGE,
                          payload: { id: activeImageId, state: IMAGE.DETAILED },
                        });
                        dispatch({
                          type: ACTION.SET_ACTIVE_IMAGE_ID,
                          payload: nextImageId,
                        });
                      }}
                    >
                      <ReactTranslated.Translate text="Next" />
                    </button>
                  ) : (
                    <div className="chapterActions">
                      {isActiveChapterLastChapter ? (
                        <button
                          className="btn btn-block chapter-title-button main-btn story-right icon-btn"
                          onClick={() => {
                            dispatch({
                              type: ACTION.UPDATE_IMAGE,
                              payload: {
                                id: activeImage.id,
                                state: IMAGE.DETAILED,
                              },
                            });
                            setView(VIEW.REVIEW);
                          }}
                        >
                          <LineIcon name="checkmark" />{" "}
                          <ReactTranslated.Translate text="Review story" />
                        </button>
                      ) : (
                        <button
                          className="btn btn-block chapter-title-button main-btn story-right"
                          onClick={() => {
                            const activeChapterIndex = sortedChapters.findIndex(
                              (c) => c.id === state.activeChapterId
                            );
                            const nextChapterId =
                              sortedChapters[activeChapterIndex + 1].id;
                            const activeImageId = activeImage.id;
                            dispatch({
                              type: ACTION.SET_ACTIVE_CHAPTER_ID,
                              payload: nextChapterId,
                            });
                            dispatch({
                              type: ACTION.UPDATE_IMAGE,
                              payload: {
                                id: activeImageId,
                                state: IMAGE.DETAILED,
                              },
                            });
                            setView(VIEW.CHAPTER_TITLE);
                          }}
                        >
                          <ReactTranslated.Translate text="Next chapter" />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </Container>
            </div>
          </ReactTranslated.Provider>
        );
      }
      case VIEW.REVIEW: {
        return (
          <ReactTranslated.Provider
            language={language}
            translation={translation}
            className="content"
          >
            <div style={{ display: "none" }}>
              <SiteNav setLangPreference={setLangPreference} />
            </div>
            <div className="Singlestory storyReview">
              <Container className="storyReviewContainer">
                <div className="Storymeta">
                  <h2 className="Storytitle">
                    {state.title}{" "}
                    <span>
                      <ReactTranslated.Translate text="by" />{" "}
                      {currentUser.displayName}
                    </span>
                  </h2>
                </div>

                <div className="Slidenavigation">
                  <div className="slidenav prev">
                    <LineIcon name="arrow-left" />{" "}
                    <ReactTranslated.Translate text="Previous chapter" />
                  </div>
                  <div className="slidenav next">
                    <ReactTranslated.Translate text="Next Chapter" />{" "}
                    <LineIcon name="arrow-right" />
                  </div>
                </div>

                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  autoHeight={true}
                  onSlideChange={() => {}}
                  navigation={{
                    prevEl: ".prev",
                    nextEl: ".next",
                  }}
                  className="Chapterswiper"
                >
                  {sortedChapters.map((chapter, index) => (
                    <SwiperSlide data-hash="intimacy" key={chapter.id}>
                      <h1 className="Chaptertitle">
                        Chapter {index + 1}:{" " + chapter.title}
                      </h1>
                      {Object.values(chapter.images)
                        .sort(sortByIndex)
                        .map((image) => {
                          const reviewImageHeight =
                            (image.naturalHeight / image.naturalWidth) *
                            IMAGE_REVIEW_WIDTH;

                          return (
                            <Row className="ImageRow" key={image.id}>
                              <Col sm="auto" className="ImageCol">
                                <div
                                  style={{
                                    width: IMAGE_REVIEW_WIDTH,
                                    height: reviewImageHeight,
                                    pointerEvents: "none",
                                    position: "relative",
                                  }}
                                >
                                  {!image.annotationData ? (
                                    <img
                                      src={image.dataURL}
                                      alt="Uploaded by user"
                                      width={IMAGE_REVIEW_WIDTH}
                                    />
                                  ) : (
                                    <ReactPictureAnnotation
                                      scrollSpeed={0}
                                      image={image.dataURL}
                                      annotationData={image.annotationData}
                                      onSelect={() => {}}
                                      onChange={() => {}}
                                      width={IMAGE_REVIEW_WIDTH}
                                      height={reviewImageHeight}
                                      inputElement={(
                                        value,
                                        onChange,
                                        onDelete
                                      ) => (
                                        <CustomAnnotation
                                          {...{ value, onChange, onDelete }}
                                        />
                                      )}
                                      annotationStyle={{
                                        padding: 5, // text padding
                                        fontSize: 13, // text font size
                                        fontColor: "#000000", // text font color
                                        fontBackground: "#00d300", // text background color
                                        fontFamily:
                                          "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",

                                        /** stroke style **/
                                        lineWidth: 3, // stroke width
                                        shapeBackground:
                                          "hsla(120, 100%, 41%, 0.12)", // background color in the middle of the marker
                                        shapeStrokeStyle: "#00d300", // shape stroke color
                                        shadowBlur: 10, // stroke shadow blur
                                        shapeShadowStyle:
                                          "hsla(210, 9%, 31%, 0.35)", // shape shadow color

                                        /** transformer style **/
                                        transformerBackground: "#ffffff",
                                        transformerSize: 10,
                                      }}
                                    />
                                  )}
                                </div>
                              </Col>

                              {(image.text.length > 0 || image.audioBlobURL) &&
                                image.text !== "<p><br></p>" && (
                                  <Col className="TextCol">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: image.text,
                                      }}
                                    />

                                    {image.audioBlobURL && (
                                      <audio
                                        src={image.audioBlobURL}
                                        controls
                                      />
                                    )}
                                  </Col>
                                )}
                            </Row>
                          );
                        })}
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div className="Slidenavigation">
                  <div className="slidenav prev">
                    <LineIcon name="arrow-left" />{" "}
                    <ReactTranslated.Translate text="Previous chapter" />
                  </div>
                  <div className="slidenav next">
                    <ReactTranslated.Translate text="Next Chapter" />{" "}
                    <LineIcon name="arrow-right" />
                  </div>
                </div>

                <div className="review-actions">
                  <button
                    className="btn btn-block chapter-title-button mg-r icon-btn"
                    onClick={() => {
                      const lastChapter =
                        sortedChapters[sortedChapters.length - 1];
                      const lastChapterId = lastChapter.id;
                      const lastChapterImages = Object.values(
                        lastChapter.images
                      ).sort(sortByIndex);
                      const lastChapterLastImageId =
                        lastChapterImages[lastChapterImages.length - 1].id;

                      dispatch({
                        type: ACTION.SET_ACTIVE_CHAPTER_ID,
                        payload: lastChapterId,
                      });
                      dispatch({
                        type: ACTION.SET_ACTIVE_IMAGE_ID,
                        payload: lastChapterLastImageId,
                      });
                      setView(VIEW.IMAGE_DESCRIPTION);
                    }}
                  >
                    <ReactTranslated.Translate text="Back" />
                  </button>

                  <button
                    className="btn btn-block reorder-btn icon-btn"
                    onClick={() => {
                      setView(VIEW.REORDER_CHAPTERS);
                    }}
                  >
                    <LineIcon name="grid-alt" />
                    <ReactTranslated.Translate text="Reorder chapters" />
                  </button>
                </div>
              </Container>
              <div className="Storypreviewsidebar">
                {/* <h3>
                  <ReactTranslated.Translate text="Publish" />
                </h3> */}
                <div className="moduleContainer">
                  <div className="reviewModules">
                    <div className="reviewModule">
                      <h4>
                        <ReactTranslated.Translate text="Cover image" />
                      </h4>
                      {state.coverImageURL || state.coverImageBlobUrl ? (
                        <div className="coverpreviewContainer">
                          <div className="coverPreview">
                            <img
                              src={
                                state.coverImageURL ?? state.coverImageBlobUrl
                              }
                            />
                          </div>
                          <button
                            className="coverBtnalt btn"
                            onClick={() => {
                              setView(VIEW.SELECT_COVER_IMAGE);
                            }}
                          >
                            <ReactTranslated.Translate text="Change cover photo" />
                          </button>
                        </div>
                      ) : (
                        <div className="coverPreview">
                          <button
                            className="coverBtn"
                            onClick={() => {
                              setView(VIEW.SELECT_COVER_IMAGE);
                            }}
                          >
                            <ReactTranslated.Translate text="Set cover photo" />
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="reviewModule">
                      <h4>
                        <ReactTranslated.Translate text="Story introduction" />
                      </h4>
                      <textarea
                        value={state.excerpt}
                        onChange={(e) => {
                          const value = e.currentTarget.value;
                          dispatch({
                            type: ACTION.UPDATE_STORY_EXCERPT,
                            payload: value,
                          });
                        }}
                        className="excerpttext"
                        placeholder="This will help people who would like to read your story. Write your description here..."
                      />
                    </div>
                    <div className="reviewModule">
                      <h4>
                        <ReactTranslated.Translate text="Privacy settings" />
                      </h4>
                      <label>
                        <input
                          type="checkbox"
                          checked={state.isPublic}
                          onChange={(e) => {
                            const isPublic = e.currentTarget.checked;
                            dispatch({
                              type: ACTION.SET_STORY_PUBLIC,
                              payload: isPublic,
                            });
                          }}
                        />{" "}
                        <ReactTranslated.Translate text="Visible to the public" />
                      </label>
                    </div>
                  </div>
                  <div className="previewFooter">
                    <div className="reviewModule">
                      <h4>
                        <ReactTranslated.Translate text="Actions" />
                      </h4>
                      <div className="editingActions">
                        <div className="editSave">
                          <button
                            className="btn btn-block chapter-title-button mg-r icon-btn"
                            onClick={() => {
                              const nextChapterId = sortedChapters[0].id;
                              const nextImageId = Object.values(
                                state.chapters[nextChapterId].images
                              )[0].id;
                              dispatch({
                                type: ACTION.SET_ACTIVE_CHAPTER_ID,
                                payload: nextChapterId,
                              });
                              dispatch({
                                type: ACTION.SET_ACTIVE_IMAGE_ID,
                                payload: nextImageId,
                              });
                              setView(VIEW.STORY_TITLE);
                            }}
                          >
                            <ReactTranslated.Translate text="Edit" />
                          </button>
                          <button
                            className="btn btn-block chapter-title-button icon-btn"
                            onClick={() => {
                              saveStory(false);
                            }}
                          >
                            <ReactTranslated.Translate text="Save" />
                          </button>
                          <button
                            className="btn btn-block chapter-title-button main-btn icon-btn"
                            onClick={() => {
                              saveStory(true);
                            }}
                            style={{ borderColor: "#000000" }}
                          >
                            <ReactTranslated.Translate text="Publish" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactTranslated.Provider>
        );
      }
      case VIEW.REORDER_CHAPTERS: {
        return (
          <ReactTranslated.Provider
            language={language}
            translation={translation}
            className="content"
          >
            <div style={{ display: "none" }}>
              <SiteNav setLangPreference={setLangPreference} />
            </div>
            <Card className="single-step">
              <Card.Body>
                <Container>
                  <ReorderChapters
                    sortedChapters={sortedChapters}
                    dispatch={dispatch}
                  />
                  <button
                    className="btn btn-block chapter-title-button story-right"
                    onClick={() => {
                      setView(VIEW.REVIEW);
                    }}
                  >
                    <ReactTranslated.Translate text="Back to review" />
                  </button>
                </Container>
              </Card.Body>
            </Card>
          </ReactTranslated.Provider>
        );
      }
      case VIEW.SELECT_COVER_IMAGE: {
        return (
          <ReactTranslated.Provider
            language={language}
            translation={translation}
            className="content"
          >
            <div style={{ display: "none" }}>
              <SiteNav setLangPreference={setLangPreference} />
            </div>
            <Card className="single-step">
              <Card.Body>
                <Container>
                  <h3>
                    <ReactTranslated.Translate text="Choose a cover photo" />
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {sortedImages.map((image) => (
                      <div
                        key={image.id}
                        style={{
                          cursor: "pointer",
                          maxWidth: 256,
                          maxHeight: 256,

                          padding: 16,
                        }}
                        onClick={() => {
                          dispatch({
                            type: ACTION.SET_SELECTED_COVER_IMAGE_ID,
                            payload: image.id,
                          });
                          setView(VIEW.CROP_COVER_IMAGE);
                        }}
                      >
                        <img
                          src={image.dataURL}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                          alt="Uploaded by user"
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    className="btn btn-block chapter-title-button"
                    onClick={() => {
                      setView(VIEW.REVIEW);
                    }}
                  >
                    <ReactTranslated.Translate text="Back to review" />
                  </button>
                </Container>
              </Card.Body>
            </Card>
          </ReactTranslated.Provider>
        );
      }
      case VIEW.CROP_COVER_IMAGE: {
        const image = sortedImages.find(
          (i) => i.id === state.selectedCoverImageId
        );

        return (
          <ReactTranslated.Provider
            language={language}
            translation={translation}
            className="content"
          >
            <div style={{ display: "none" }}>
              <SiteNav setLangPreference={setLangPreference} />
            </div>
            <Card className="single-step">
              <Card.Body>
                <Container className="cropContainer">
                  <h3>
                    <ReactTranslated.Translate text="Crop cover image" />
                  </h3>

                  <ReactCrop
                    src={image.dataURL}
                    onImageLoaded={(img) => {
                      croppingImageRef.current = img;
                    }}
                    crop={state.coverImageCrop}
                    onChange={(crop) => {
                      dispatch({
                        type: ACTION.SET_COVER_IMAGE_CROP,
                        payload: crop,
                      });
                    }}
                    onComplete={(crop) => {
                      // Get cropped image as a blob
                      const image = croppingImageRef.current;
                      const canvas = document.createElement("canvas");

                      const scaleX = image.naturalWidth / image.width;
                      const scaleY = image.naturalHeight / image.height;
                      const ctx = canvas.getContext("2d");
                      const pixelRatio = window.devicePixelRatio;

                      canvas.width = crop.width * pixelRatio * scaleX;
                      canvas.height = crop.height * pixelRatio * scaleY;

                      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
                      ctx.imageSmoothingQuality = "high";

                      ctx.drawImage(
                        image,
                        crop.x * scaleX,
                        crop.y * scaleY,
                        crop.width * scaleX,
                        crop.height * scaleY,
                        0,
                        0,
                        crop.width * scaleX,
                        crop.height * scaleY
                      );

                      canvas.toBlob((blob) => {
                        const coverImageId = uuidv4();

                        const blobUrl = URL.createObjectURL(blob);

                        dispatch({
                          type: ACTION.SET_COVER_IMAGE_ID_AND_BLOB,
                          payload: { id: coverImageId, blob, blobUrl },
                        });
                      }, "image/jpeg");
                    }}
                  />
                  <div className="cropFooter">
                    <button
                      className="btn btn-block chapter-title-button story-right"
                      onClick={() => {
                        setView(VIEW.REVIEW);
                      }}
                    >
                      <ReactTranslated.Translate text="Set cover image" />
                    </button>
                    <button
                      className="btn btn-block chapter-title-button story-right"
                      onClick={() => {
                        setView(VIEW.SELECT_COVER_IMAGE);
                      }}
                    >
                      <ReactTranslated.Translate text="Back" />
                    </button>
                  </div>
                </Container>
              </Card.Body>
            </Card>
          </ReactTranslated.Provider>
        );
      }
      case VIEW.SAVING_STORY: {
        return (
          <Card className="single-step">
            <Card.Body>
              <Container>
                <Spinner />
              </Container>
            </Card.Body>
          </Card>
        );
      }
      default: {
        return <div>Unknown view: {view}</div>;
      }
    }
  };

  const viewToRender = getView();

  return (
    <>
      <div className="storyTelling">
        {viewToRender}

        {/* {![VIEW.LOADING_SAVED_STORY, VIEW.SAVING_STORY].includes(view) &&
          sortedImages.length > 0 &&
          Stepper} */}

        <button
          className="Backbutton"
          onClick={() => {
            if (state.title === "" && sortedChapters.length === 0) {
              history.push("/my-herstories");
            } else {
              setCloseModalOpen(true);
            }
          }}
        >
          <LineIcon name="close" size="xs" />
        </button>
      </div>

      <ReactTranslated.Provider
        language={language}
        translation={translation}
        className="content"
      >
        <div style={{ display: "none" }}>
          <SiteNav setLangPreference={setLangPreference} />
        </div>
        <Modal
          show={isCloseModalOpen}
          onHide={() => {
            setCloseModalOpen(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>
                <ReactTranslated.Translate text="Save draft" />
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactTranslated.Translate text="Would you like to save a draft or discard your changes?" />
          </Modal.Body>
          <Modal.Footer>
            <div style={{ display: "flex" }}>
              <Button
                variant="secondary"
                onClick={() => {
                  setCloseModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  history.push("/");
                }}
              >
                Discard changes
              </Button>
              <Button
                variant="primary"
                onClick={async () => {
                  setCloseModalOpen(false);
                  await saveStory(false);
                }}
              >
                <ReactTranslated.Translate text="Save changes" />
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </ReactTranslated.Provider>
    </>
  );
};

export default Storytelling;
