import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import LineIcon from "react-lineicons";
import * as ReactTranslated from "react-translated";
import translation from "./translation";
import SiteNav from "./SiteNav";

export default function ForgotPassword(props) {
  const [language, setLangPreference] = useState("en");
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage("Check your inbox for further instructions");
    } catch {
      setError(
        "Failed to reset password. Your email address may be incorrect."
      );
    }

    setLoading(false);
  }

  return (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content"
    >
      <div style={{ display: "none" }}>
        <SiteNav setLangPreference={setLangPreference} />
      </div>
      <Card className="Login">
        <Card.Body>
          <Container>
            <h2 className="text-center mb-4">
              <ReactTranslated.Translate text="Password reset" />
            </h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>
                  <ReactTranslated.Translate text="Email" />
                </Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Button
                disabled={loading}
                className="btn w-100 main-btn"
                type="submit"
                style={{ marginTop: "32px" }}
              >
                <ReactTranslated.Translate text="Reset password" />
              </Button>
            </Form>
            <div className="w-100 text-center mt-3">
              <Link to="/login">
                <ReactTranslated.Translate text="Login" />
              </Link>
            </div>
            <div className="w-100 text-center mt-2">
              <ReactTranslated.Translate text="Need an account?" />{" "}
              <Link to="/signup">
                <ReactTranslated.Translate text="Sign up" />
              </Link>
            </div>
          </Container>
        </Card.Body>
        <Link to="/">
          <button className="Backbutton">
            <LineIcon name="close" size="xs" />
          </button>
        </Link>
      </Card>
    </ReactTranslated.Provider>
  );
}
