// @ts-check

import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import LineIcon from "react-lineicons";
import { Link, useHistory } from "react-router-dom";
import * as ReactTranslated from "react-translated";
import { fetchStoriesByUserId } from "../actions";
import { useAuth } from "../contexts/AuthContext";
import { getEditStoryURL } from "../utils";
import DeleteStoryModal from "./delete-story-modal";
import Footer from "./footer";
import SiteNav from "./SiteNav";
import Spinner from "./spinner";
import { getCoverImageURL } from "./StoryDetail";
import translation from "./translation";

const MyStories = () => {
  const [language, setLangPreference] = useState("en");
  const [stories, setStories] = useState(null);
  const [deleteStory, setDeleteStory] = useState(null);
  const history = useHistory();

  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  const fetchStories = async () => {
    fetchStoriesByUserId(userId).then((data) => {
      setStories(data);
      console.log("data ", data);
    });
  };

  useEffect(() => {
    if (userId) {
      fetchStories();
    }
  }, [userId]);

  return (
    <ReactTranslated.Provider language={language} translation={translation}>
      <SiteNav setLangPreference={setLangPreference} />
      <div className="Stories">
        <div className="Storymeta">
          <h1>
            <ReactTranslated.Translate text="Your Herstories" />
          </h1>
        </div>
        {stories ? (
          <Container className="Storiescontainer">
            <Row>
              {stories.length > 0 ? (
                stories.map((story) => {
                  const coverImageURL = getCoverImageURL(story);
                  const editStoryURL = getEditStoryURL(story.id);
                  const storyDetailURL = `/story/${story.id}`;

                  return (
                    <Col sm="6" className="Storylist" key={story.id}>
                      {coverImageURL && (
                        <div
                          className="Storythumb"
                          style={{
                            backgroundImage: `url(${coverImageURL})`,
                          }}
                        >
                          {!story.isPublished ? (
                            <div className="badge">
                              <ReactTranslated.Translate text="Draft" />
                            </div>
                          ) : (
                            <div className="badge">
                              <ReactTranslated.Translate text="Published" />
                            </div>
                          )}
                          {story.isPublic ? (
                            <div className="badge privacy">
                              <ReactTranslated.Translate text="Public" />
                            </div>
                          ) : (
                            <div className="badge privacy">
                              <ReactTranslated.Translate text="Private" />
                            </div>
                          )}

                          <Link to={editStoryURL}>
                            <button className="editListBtn listBtn icon-btn">
                              <LineIcon name="pencil-alt" size="xs" />
                              <ReactTranslated.Translate text="Edit" />
                            </button>
                          </Link>
                          <button
                            className="deleteListBtn listBtn"
                            onClick={() => {
                              setDeleteStory(story);
                            }}
                          >
                            <LineIcon name="trash-can" size="xs" />
                          </button>
                        </div>
                      )}

                      <Link to={storyDetailURL}>
                        <div className="caption">
                          <h3>{story.title}</h3>
                          {story.excerpt && <p>{story.excerpt}</p>}
                        </div>
                      </Link>
                    </Col>
                  );
                })
              ) : (
                <div>No stories found.</div>
              )}
            </Row>
          </Container>
        ) : (
          <Spinner />
        )}
      </div>

      <DeleteStoryModal
        story={deleteStory}
        isOpen={!!deleteStory}
        close={() => {
          setDeleteStory(null);
        }}
        afterDelete={async () => {
          await fetchStories();
        }}
      />

      <div className="Storyfooter">
        <Link to={`/new-story`}>
          <button className="btn link-btn">
            <ReactTranslated.Translate text="Add another story" />
          </button>
        </Link>
      </div>
      <Footer />
    </ReactTranslated.Provider>
  );
};

export default MyStories;
