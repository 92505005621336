// @ts-check

import React, { forwardRef } from "react";

// @ts-ignore
const DraggingChapter = forwardRef(({ chapter, ...props }, ref) => {
  return (
    <div {...props} ref={ref} className="sortableChapter">
      <h2>{chapter.title}</h2>
    </div>
  );
});

export default DraggingChapter;
