// @ts-check

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import * as faceapi from "@vladmandic/face-api";
import React, { useRef, useState } from "react";
import { Card } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "react-quill/dist/quill.snow.css";
import "swiper/swiper.scss";
import { v4 as uuidv4 } from "uuid";
import { getModel, setModel } from "../../model.js";
import Spinner from "../spinner.js";
import DraggingImage from "./dragging-image.js";
import { ACTION, IMAGE, VIEW } from "./index.js";
import SortableImage from "./sortable-image";
import { getDataURL } from "../../utils.js";
import LineIcon from "react-lineicons";
import * as ReactTranslated from "react-translated";
import translation from "../translation";
import SiteNav from "../SiteNav";

export const getInitialImageState = () => ({
  annotationData: false,
  audioBlob: null,
  audioBlobURL: null,
  audioURL: null,
  createdAt: new Date(),
  dataURL: null,
  file: null,
  id: null,
  index: null,
  isAnnotationEnabled: false,
  isRecordPaneOpen: false,
  progress: 0,
  rotation: 0,
  state: IMAGE.SELECTED,
  text: "",
});

const SelectImages = ({
  activeChapterImages,
  setView,
  dispatch,
  selectedCoverImageId,
  coverImageURL,
  coverImageId,
  props,
}) => {
  const [language, setLangPreference] = useState("en");
  const inputRef = useRef(null);
  const imageRefs = useRef({});

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const [dndActiveId, setDndActiveId] = useState(null);

  const isProcessing = activeChapterImages.some(
    (image) => image.state === IMAGE.RUNNING_ML
  );

  return (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content"
    >
      <div style={{ display: "none" }}>
        <SiteNav setLangPreference={setLangPreference} />
      </div>
      <Card className="single-step">
        <Card.Body>
          <Container>
            <div className="chapter-title">
              <h1>
                <ReactTranslated.Translate text="Add photos to this chapter" />
              </h1>

              <p>
                <ReactTranslated.Translate text="You can rotate and reorder your images before continuing" />
              </p>
            </div>

            <input
              type="file"
              multiple
              hidden={true}
              ref={inputRef}
              accept=".jpg,.jpeg,.png"
              onChange={async (e) => {
                if (e.target.value !== "") {
                  const files = Array.from(e.target.files);

                  const payload = {};
                  for (const file of files) {
                    const imageId = uuidv4();

                    const dataURL = await getDataURL(file);

                    const initialImageState = getInitialImageState();

                    payload[imageId] = {
                      ...initialImageState,
                      ...{
                        id: imageId,
                        file,
                        dataURL,
                        index: activeChapterImages.length,
                      },
                    };
                  }

                  dispatch({
                    type: ACTION.ADD_IMAGES_TO_ACTIVE_CHAPTER,
                    payload,
                  });
                  e.target.value = ""; // Reset to allow adding more images
                }
              }}
            />

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={(event) => {
                const { active } = event;
                setDndActiveId(active.id);
              }}
              onDragEnd={(event) => {
                const { active, over } = event;

                if (active.id !== over.id) {
                  const prevImageOrders = activeChapterImages.map((i) => i.id);

                  const activeIndex = prevImageOrders.indexOf(active.id);
                  const overIndex = prevImageOrders.indexOf(over.id);

                  const nextImageOrders = arrayMove(
                    prevImageOrders,
                    activeIndex,
                    overIndex
                  );

                  const nextIndices = {};
                  for (let x = 0; x < nextImageOrders.length; x++) {
                    nextIndices[nextImageOrders[x]] = x;
                  }

                  dispatch({
                    type: ACTION.REORDER_ACTIVE_CHAPTER_IMAGES,
                    payload: nextIndices,
                  });
                }
                setDndActiveId(null);
              }}
            >
              <SortableContext
                items={activeChapterImages}
                strategy={rectSortingStrategy}
              >
                <div className="uploadedImages">
                  {activeChapterImages.map((image) => {
                    return (
                      <SortableImage
                        key={image.id}
                        image={image}
                        imageRefs={imageRefs}
                        dispatch={dispatch}
                        dndActiveId={dndActiveId}
                        selectedCoverImageId={selectedCoverImageId}
                        coverImageURL={coverImageURL}
                        coverImageId={coverImageId}
                      />
                    );
                  })}
                </div>
              </SortableContext>
              <DragOverlay>
                {dndActiveId ? (
                  <DraggingImage
                    // @ts-ignore
                    image={activeChapterImages.find(
                      (i) => i.id === dndActiveId
                    )}
                  />
                ) : null}
              </DragOverlay>
            </DndContext>

            {isProcessing ? (
              <Spinner />
            ) : (
              <>
                <button
                  className="btn-upload"
                  onClick={() => {
                    inputRef.current.click();
                  }}
                >
                  <LineIcon name="cloud-upload" />
                  <ReactTranslated.Translate text="Upload images" />
                </button>
                <br />
                <br />

                <button
                  className="btn btn-block chapter-title-button btn-inv storybackBtn"
                  onClick={async () => {
                    setView(VIEW.CHAPTER_TITLE);
                  }}
                >
                  <ReactTranslated.Translate text="Back" />
                </button>

                {activeChapterImages.length > 0 && (
                  <button
                    className="btn btn-block chapter-title-button main-btn"
                    onClick={async () => {
                      for (const image of activeChapterImages) {
                        dispatch({
                          type: ACTION.UPDATE_IMAGE,
                          payload: {
                            id: image.id,
                            state: IMAGE.RUNNING_ML,
                          },
                        });

                        // Machine learning
                        // Load the model only once when needed
                        if (!getModel()) {
                          await cocoSsd.load().then((m) => {
                            setModel(m);
                          });
                          await faceapi.nets.ssdMobilenetv1.loadFromUri(
                            "/models"
                          );
                          await faceapi.nets.faceLandmark68Net.loadFromUri(
                            "/models"
                          );
                          await faceapi.nets.faceExpressionNet.loadFromUri(
                            "/models"
                          );
                        }

                        // Classify the image.
                        console.log("Starting ML");
                        const mldone = true;
                        console.log("ml done", mldone);
                        const imgEl = imageRefs.current[image.id];
                        const predictions = await getModel().detect(imgEl);
                        console.log("predictions", predictions);

                        // Get intimacy
                        const bodyX = [];
                        const bodyY = [];
                        const bodyWidth = [];
                        let numPeople = 0;
                        let nonHumanObject = null;

                        for (const i of predictions) {
                          if (i.class === "person") {
                            numPeople++;
                            bodyX.push(i.bbox[0]);
                            bodyY.push(i.bbox[1]);
                            bodyWidth.push(i.bbox[2]);
                          } else {
                            nonHumanObject = i.class;
                          }
                        }

                        let intimacyPerception = null;
                        if (numPeople === 2) {
                          let distX;
                          if (bodyX[0] > bodyX[1]) {
                            distX =
                              (bodyX[0] - (bodyX[1] + bodyWidth[1])) /
                              bodyWidth[1];
                            console.log("distX", distX);
                          } else {
                            distX =
                              (bodyX[1] - (bodyX[0] + bodyWidth[0])) /
                              bodyWidth[0];
                            console.log("distX", distX);
                          }
                          console.log("Distance", distX * 100);
                          if (distX <= -0.2) {
                            intimacyPerception = "really close";
                          } else if (distX < 0.05 && distX > -0.2) {
                            intimacyPerception = "close";
                          } else if (distX > 0.05 && distX < 0.08) {
                            intimacyPerception = "distant";
                          } else if (distX > 0.08) {
                            intimacyPerception = "very distant";
                          }
                        }

                        const isCrowd = numPeople >= 4;
                        console.log("isCrowd", isCrowd);

                        console.log("intimacyPerception", intimacyPerception);

                        // Get expressions
                        const detection = await faceapi
                          .detectAllFaces(imgEl)
                          .withFaceExpressions();

                        console.log("detection", detection);

                        const numHappy = detection.filter(
                          (fd) => fd.expressions.happy >= 0.6
                        ).length;
                        const numSad = detection.filter(
                          (fd) =>
                            fd.expressions.angry >= 0.6 ||
                            fd.expressions.sad >= 0.6
                        ).length;

                        console.log(
                          "there are " +
                            numHappy +
                            " happy people in this photo"
                        );
                        console.log(
                          "there are " + numSad + " sad people in this photo"
                        );

                        let moodPerception = null;
                        if (numHappy / detection.length >= 0.5) {
                          moodPerception = "happy";
                        } else if (numSad / detection.length >= 0.5) {
                          moodPerception = "serious";
                        }

                        dispatch({
                          type: ACTION.UPDATE_IMAGE,
                          payload: {
                            id: image.id,
                            state: IMAGE.ML_COMPLETE,
                            mldone,
                            predictions,
                            detection,
                            intimacyPerception,
                            isCrowd,
                            moodPerception,
                            nonHumanObject,
                          },
                        });

                        dispatch({
                          type: ACTION.SET_ACTIVE_IMAGE_ID,
                          payload: activeChapterImages[0].id,
                        });
                      }
                      setView(VIEW.IMAGE_DESCRIPTION);
                    }}
                  >
                    <ReactTranslated.Translate text="Next" />
                  </button>
                )}
              </>
            )}
          </Container>
        </Card.Body>
      </Card>
    </ReactTranslated.Provider>
  );
};

export default SelectImages;
