// @ts-check

import React, { useRef, useState } from "react";
import { Container, Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import { auth } from "../firebase";
import { updateProfile } from "firebase/auth";
import LineIcon from "react-lineicons";
import * as ReactTranslated from "react-translated";
import translation from "./translation";
import SiteNav from "./SiteNav";

export default function Signup(props) {
  const [language, setLangPreference] = useState("en");
  const displayNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      const user = auth.currentUser;

      updateProfile(user, {
        displayName: displayNameRef.current.value,
      })
        .then(() => {
          history.push("/");
        })
        .catch((error) => {
          console.trace(error);
        });
    } catch {
      setError("Failed to create an account");
    }

    setLoading(false);
  }

  return (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content"
    >
      <div style={{ display: "none" }}>
        <SiteNav setLangPreference={setLangPreference} />
      </div>
      <Card className="Login">
        <Card.Body>
          <Container>
            <h2 className="text-center mb-4">
              {" "}
              <ReactTranslated.Translate text="Sign up to tell your Herstory" />
            </h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="displayName">
                <Form.Label>
                  <ReactTranslated.Translate text="First name" />
                </Form.Label>
                <Form.Control type="text" ref={displayNameRef} required />
              </Form.Group>
              <Form.Group id="email">
                <Form.Label>
                  <ReactTranslated.Translate text="Email" />
                </Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>
                  <ReactTranslated.Translate text="Password" />
                </Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Form.Group id="password-confirm">
                <Form.Label>
                  <ReactTranslated.Translate text="Password confirmation" />
                </Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  required
                />
              </Form.Group>
              <Button
                disabled={loading}
                className="w-100 main-btn"
                type="submit"
              >
                <ReactTranslated.Translate text="Sign up" />
              </Button>
            </Form>

            <div className="w-100 text-center mt-2">
              <ReactTranslated.Translate text="Already have an account?" />{" "}
              <Link to="/login">
                <ReactTranslated.Translate text="Login" />
              </Link>
            </div>
          </Container>
        </Card.Body>

        <Link to="/">
          <button className="Backbutton">
            <LineIcon name="close" size="xs" />
          </button>
        </Link>
      </Card>
    </ReactTranslated.Provider>
  );
}
