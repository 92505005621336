import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import * as ReactTranslated from "react-translated";
import translation from "./translation";
import Br from "./Br";
import TransLink from "./TransLink";
import { Link } from "react-router-dom";

function Intro(props) {
  const readlink = "/herstories";
  const createlink = "/new-story";
  const createstring = "create your own";

  return (
    <ReactTranslated.Provider language={props.lang} translation={translation}>
      <div className="Intro narrowContainer">
        <Container>
          <Row>
            <Col>
              <p>
                <ReactTranslated.Translate
                  text="intro"
                  renderMap={{
                    renderBr: () => <Br />,
                    renderHerstoriesLink: () => (
                      <TransLink
                        pagelink={readlink}
                        pagestring={`${
                          props.lang != "en"
                            ? "Bekijk alle Herstories"
                            : "read the Herstories"
                        }`}
                      />
                    ),
                    renderCreateLink: () => (
                      <TransLink
                        pagelink={createlink}
                        pagestring={createstring}
                      />
                    ),
                  }}
                />
              </p>
              <br></br>
              <Link to="/new-story">
                <button className="btn" id="create" style={{ marginLeft: 0 }}>
                  <ReactTranslated.Translate text="Create" />
                </button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </ReactTranslated.Provider>
  );
}

export default Intro;
