export default {
  // Full example
  "My Herstories": {
    en: "My Herstories",
    nl: "Mijn Herstories",
    ar: "قصصها” الخاضة بى”",
  },
  Profile: {
    en: "Profile",
    nl: "Profiel",
    ar: "الملف الشخصى",
  },
  Login: {
    en: "Login",
    nl: "Inloggen",
    ar: "تسجيل دخول",
  },
  Logout: {
    en: "Logout",
    nl: "Uitloggen",
    ar: " تسجيل خروج",
  },
  "Herstory Samples": {
    en: "Herstory Samples",
    nl: "Voorbeeld Herstories",
    ar: "عينّات ”قصتها”",
  },
  "A Herstory is": {
    en: "A Herstory is a collection of photographs, sounds and text.",
    nl: "Een Herstory is een verzameling van foto's, geluiden en tekst.",
    ar: "”قصتها” هى عبارة عن مجموعة من الصور والأصوات والنصوص.  ",
  },
  "View all Herstories": {
    en: "View all herstories",
    nl: "Bekijk alle herstories",
    ar: "عرض كل ”قصصها”",
  },
  "All Herstories": {
    en: "All Herstories",
    nl: "Alle Herstories",
    ar: "كل ”قصصها”",
  },
  "created on this website": {
    en: "created on this website",
    nl: "die op deze website zijn gemaakt",
    ar: "تم إنشائها عبر هذا الموقع الإلكتروني ",
  },
  "Explore all Herstories": {
    en: "Explore all Herstories",
    nl: "Ontdek alle Herstories",
    ar: "تصفح جميع  ”قصصها”",
  },
  "Read Herstory": {
    en: "Read Herstory",
    nl: "Bekijk Herstory",
    ar: "اقرأ ”قصتها”",
  },
  About: {
    en: "About",
    nl: "Over",
    ar: "نبذة",
  },
  Create: {
    en: "Create Herstory",
    nl: "Maak een Herstory",
    ar: "إنشاء ”قصتها”",
  },
  projectby: {
    en: "A project by",
    nl: "Een project van",
    ar: "مشروع بواسطة",
  },
  supportedby: {
    en: "Kindly supported by",
    nl: "Vriendelijk ondersteund door",
    ar: "نتكرم بالدعم من",
  },
  address: {
    en: "affect lab <Br> Wibautstraat 150 <Br> 1093GR Amsterdam <Affectlab>",
    nl: "affect lab<Br> Wibautstraat 150<Br> 1093GR Amsterdam <Affectlab>",
    ar: "affect lab<Br> Wibautstraat 150<Br> 1093GR Amsterdam <Affectlab>",
  },
  welcome: {
    en: "How to Tell a Herstory.",
    nl: "How to Tell a Herstory.",
    ar: "”كيف تروي قصتها”",
  },
  intro: {
    en: "To tell a herstory is to see the world through the eyes of women. Herstories are narratives of inspiration and meaning. Herstories record the experiences of mothers, daughters, grandmothers and aunties, and celebrate them for their role in the family and society. To begin you will need a few photos. No rush, take your time to select these. Your selection can be just one or as many as 100. It’s up to you how big the story becomes. Dust off the family photo album. Scroll through the photo albums on your phone. These photos will form the core of your story.",
    nl: "Een herstory vertellen is de wereld door de ogen van vrouwen te zien. Herstories zijn narratieven van inspiratie en betekenis. Herstories leggen de ervaringen van moeders, dochters, grootmoeders en tantes vast en viert hen voor hun rol in het gezin en de samenleving. Om te beginnen heb je een paar foto's nodig. Geen haast, neem de tijd om deze te selecteren. Je selectie kan er slechts één of zoveel als 100 zijn. Het is aan jou hoe groot het verhaal wordt. Stof het familiefotoalbum af. Blader door de fotoalbums op je telefoon. Deze foto's vormen de kern van je verhaal.",
    ar: "أن تخبر ”قصتها” هو أن ترى العالم من خلال عيون النساء. قصصها هي روايات عن الإلهام والمعنى. تسجل قصصها تجارب الأمهات والبنات والجدات والعمات وتحتفي بدورهن في الأسرة والمجتمع. لتبدأ سوف تحتاج إلى بعض الصور. لا داعي للاندفاع ، خذ وقتك لاختيار هذه. يمكن أن يكون اختيارك واحدًا أو أكثر من 100. الأمر متروك لك ان تحدد كبر القصة.  قم بنفض الغبار عن ألبوم صور العائلة. قم بالتمرير خلال ألبومات الصور على هاتفك. ستشكل هذه الصور جوهر قصتك.",
  },
  rewriting: {
    en: "Some stories are never told. Even though they deserve to be heard. This is a space to retell history. Your herstory begins here. ",
    nl: "Sommige verhalen worden nooit verteld. Ook al verdienen ze het om gehoord te worden. Dit is een ruimte om de geschiedenis opnieuw te vertellen. Je herstory begint hier.",
    ar: "بعض القصص لم ترو قط. على الرغم من أنها تستحق أن تلقى أذناً صاغية. هذه هي فرصتك لإعادة رواية التاريخ",
  },
  "about title": {
    en: "About",
    nl: "Over",
    ar: "نبذة",
  },
  "about text": {
    en: "Sometimes a beautiful story needs a nudge to bring it to life. How to Tell a Herstory is a conversation between storytellers, their photographs and machine learning. Our unique free-to-use toolkit analyses your photos and prompts you, the storyteller, into a narrative flow. <Br><Br>At the heart of each herstory is a celebration of women and their experiences. Every storyteller is welcome. All herstories are born out of a set of photographs uploaded into the machine learning toolkit currently being trained on family photographs. Photos of weddings, birthday parties, a day at the beach, cooking a meal, doing chores in the house, a family gathering -- these seemingly everyday photos give us vital insights into our cultural identity and sense of the world. <Br><Br>The aim of the project is to offer a unique space for telling stories about women. It opens a space for thinking and critical reflection on our contemporary relationship with images, familial power structures and memory making. <Br><Br>How to Tell a HerStory is a project by affect lab, a creative studio and cultural insights practice.",
    nl: "Soms heeft een mooi verhaal een zetje nodig om het tot leven te brengen. How to Tell a Herstory is een gesprek tussen verhalenvertellers, hun foto's en machine learning. Onze unieke gratis te gebruiken toolkit analyseert uw foto's en zet u, de verteller, in een verhalende stroom. <Br><Br>In het hart van elke herstory is een viering van vrouwen en hun ervaringen. Elke verteller is welkom. Alle herstories zijn ontstaan uit een reeks foto's die zijn geüpload naar de machine learning-toolkit die momenteel wordt getraind op familiefoto's. Foto's van bruiloften, verjaardagsfeestjes, een dag op het strand, een maaltijd koken, klusjes in huis doen, een familiebijeenkomst - deze ogenschijnlijk alledaagse foto's geven ons essentiële inzichten in onze culturele identiteit en gevoel voor de wereld. <Br><Br>Het doel van het project is om een unieke ruimte te bieden voor het vertellen van verhalen over vrouwen. Het opent een ruimte voor nadenken en kritische reflectie over onze hedendaagse relatie met beelden, familiale machtsstructuren en het maken van herinneringen. <Br><Br>How to Tell a HerStory is een project van affectlab, een creatieve studio en een praktijk voor culturele inzichten.",
    ar: "",
  },
  Submit: {
    en: "Submit",
    nl: "Verzenden",
    ar: "إرسال",
  },
  "Your Herstories": {
    en: "Your Herstories",
    nl: "Jouw Herstories",
    ar: "قصصها” الخاصة بك”",
  },
  Edit: {
    en: "Edit",
    nl: "Bewerken",
    ar: "تعديل",
  },
  Draft: {
    en: "Draft",
    nl: "Concept",
    ar: "مقترح",
  },
  Private: {
    en: "Private",
    nl: "Privé",
    ar: "خاص",
  },
  Published: {
    en: "Published",
    nl: "Gepubliceerd",
    ar: "منشور",
  },
  Public: {
    en: "Public",
    nl: "Openbaar",
    ar: "عام",
  },
  "Add another story": {
    en: "Add another story",
    nl: "Voeg nog een verhaal toe",
    ar: "إضافة قصة اخرى",
  },
  Next: {
    en: "Next",
    nl: "Volgende",
    ar: "التالي",
  },
  Back: {
    en: "Back",
    nl: "Terug",
    ar: "السابق",
  },
  "What is the title of your Herstory?": {
    en: "What is the title of your Herstory? (you can edit this later)",
    nl: "Wat is de titel van je Herstory? (je kunt dit later bewerken)",
    ar: "ما هو عنوان ”قصتها” الخاصة بك؟ (يمكنك التعديل لاحقاً)",
  },
  "What is the title of this chapter?": {
    en: "What is the title of this chapter?",
    nl: "Wat is de titel van dit hoofdstuk?",
    ar: "ما هو عنوان هذا الفصل",
  },
  "In your Herstory we ask": {
    en: "In your Herstory we ask you to think about the key moments of the story, we will divide them into chapters. (you can change this later)",
    nl: "In je Herstory vragen we je na te denken over de belangrijke momenten van het verhaal, we zullen ze in hoofdstukken verdelen. (je kunt dit later aanpassen)",
    ar: "فى اطار ”قصتها” الخاصة بك، نسألك ان تستحضر اللقطات الفارقة فى سياق القصة، سوف يتم تقسيمها إلى فصول. (يمكنك التعديل لاحقاً),",
  },
  "Add photos to this chapter": {
    en: "Add photos to this chapter",
    nl: "Voeg foto’s toe aan dit hoofdstuk",
    ar: "اضف صور الى هذا الفصل",
  },
  "You can rotate and reorder your images before continuing": {
    en: "You can rotate and reorder your images before continuing",
    nl: "Je kunt je  afbeeldingen roteren en opnieuw ordenen voordat u verder gaat.",
    ar: "يمكنك تدوير وإعادة ترتيب الصور الخاصة بك قبل الإستكمال",
  },
  "Please confirm that you would like to delete": {
    en: "Please confirm that you would like to delete the story titled",
    nl: "Gelieve te bevestigen dat je het verhaal met de titel",
    ar: "برجاء التأكيد إذا تريد حذف القصة بعنوان {العنوان}. لا يمكن استرجاع هذا الإجراء.",
  },
  "This action is not reversible": {
    en: "This action is not reversible.",
    nl: "wenst te verwijderen. Deze actie is niet omkeerbaar.",
    ar: "يمكن التراجع عن هذا الإجراء",
  },
  "Upload images": {
    en: "Upload images",
    nl: "Upload foto’s",
    ar: "رفع صور",
  },
  "Annotate image": {
    en: "Annotate image",
    nl: "Annoteer foto",
    ar: "تعليق على الصورة",
  },
  "Add image": {
    en: "Add image",
    nl: "Foto toevoegen",
    ar: "إضافة صورة",
  },
  Save: {
    en: "Save",
    nl: "Opslaan",
    ar: "حفظ",
  },
  "Add audio": {
    en: "Add audio",
    nl: "Audio toevoegen",
    ar: "إضافة ملف صوتي",
  },
  Record: {
    en: "Record",
    nl: "Opnemen",
    ar: "تسجيل",
  },
  "Upload audio (mp3 / wav)": {
    en: "Upload audio (mp3 / wav)",
    nl: "Audio uploaden",
    ar: "تحميل ملف صوتى(mp3/wav)",
  },
  "Remove audio": {
    en: "Remove audio",
    nl: "Verwijder Audio",
    ar: "حذف ملف صوتى",
  },
  "Add another chapter": {
    en: "Add another chapter",
    nl: "Voeg nog een hoofdstuk toe",
    ar: "إضافة فصل آخر",
  },
  Review: {
    en: "Review",
    nl: "Review",
    ar: "معاينة",
  },
  "Next chapter": {
    en: "Next chapter",
    nl: "Volgende hoofdstuk",
    ar: "الفصل التالي",
  },
  Publish: {
    en: "Publish",
    nl: "Publiceren",
    ar: "نشر ",
  },
  "Cover image": {
    en: "Cover image",
    nl: "Omslagfoto",
    ar: "صورة الغلاف",
  },
  "Story introduction": {
    en: "Story introduction",
    nl: "Introductie",
    ar: "مقدمة القصة",
  },
  "Privacy settings": {
    en: "Privacy settings",
    nl: "Privacy instellingen",
    ar: "إعدادات الخصوصية ",
  },
  "Visible to the public": {
    en: "Visible to the public",
    nl: "Zichtbaar voor het publiek ",
    ar: "مرئي للعامة",
  },
  "Reorder chapters": {
    en: "Reorder chapters",
    nl: "Hoofdstuk opnieuw ordenen",
    ar: "إعادة ترتيب الفصول",
  },
  "Back to review": {
    en: "Back to review",
    nl: "Terug naar overzicht",
    ar: "عودة إلى المراجعة",
  },
  Share: {
    en: "Share",
    nl: "Delen",
    ar: "مشاركة",
  },
  "Link copied": {
    en: "Link copied",
    nl: "Link gekopieerd ",
    ar: "تم نسخ الرابط",
  },
  "Delete story": {
    en: "Delete story",
    nl: "Verwijder verhaal",
    ar: "حذف القصة",
  },
  Delete: {
    en: "Delete",
    nl: "Verwijder",
    ar: "حذف",
  },
  Cancel: {
    en: "Cancel",
    nl: "Annuleer",
    ar: "إلغاء ",
  },
  Hi: {
    en: "Hi",
    nl: "Hoi",
    ar: "أهلا",
  },
  "I'm going to help you tell your story": {
    en: "I'm going to help you tell your story. To get started, tell me who took this photo?",
    nl: "ik ga je helpen je verhaal te vertellen. Om te beginnen, vertel me wie deze foto nam?",
    ar: "، سوف اقوم بمساعدتك لتروي القصة الخاصة بك. لنبدأ، أخبرني بمن قام بالتقاط هذه الصورة؟",
  },
  "What is in the photo?": {
    en: "What is in the photo?",
    nl: "Wat staat er op de foto?",
    ar: "ماذا يوجد في هذه الصورة؟",
  },
  "When and where was it taken?": {
    en: "When and where was it taken?",
    nl: "Wanneer en waar is het genomen?",
    ar: "متى و أين تم التقاط هذه الصورة؟ ",
  },
  "I see a": {
    en: "I see a",
    nl: "Ik zie een",
    ar: "أرى ",
  },
  "I see a lot of people in this photo.": {
    en: "I see a lot of people in this photo. Was this a special event? Tell me about this day.",
    nl: "Ik zie een heleboel mensen op deze foto. Was dit een speciale gebeurtenis? Vertel me over deze dag.",
    ar: "أرى أشخاص عدّة فى هذه الصورة.هل كان هذا الحدث مميز؟ اخبرنى عن ذاك اليوم.",
  },
  "Was this a special event? Tell me about this day.": {
    en: "Was this a special event? Tell me about this day.",
    nl: "Ik zie een",
    ar: "هل كان هذا الحدث مميز؟ اخبرنى عن ذاك اليوم.",
  },
  "in this photo. Is there a special object you would like to write about?": {
    en: "in this photo. Is there a special object you would like to write about?",
    nl: "op deze foto. Is er een speciaal voorwerp waar je over zou willen schrijven?",
    ar: "فى هذه الصورة. هل هناك جسم تود الكتابة عنه؟",
  },
  "Did you notice anything unusual in this photo?": {
    en: "Did you notice anything unusual in this photo?",
    nl: "Heb je iets ongewoons opgemerkt op deze foto?",
    ar: "هل لاحظت أي شيء خارج عن المألوف فى هذه الصورة؟",
  },
  "The mood in this photo seems": {
    en: "The mood in this photo seems",
    nl: "De stemming in deze foto lijkt",
    ar: "يبدو المزاج فى هذه الصورة ",
  },
  "Tell me more about the emotions in this image.": {
    en: "Tell me more about the emotions in this image.",
    nl: "Vertel me meer over de emoties in dit beeld.",
    ar: "اخبرنى عن المشاعر فى هذه الصورة",
  },
  "What do you imagine you could smell or hear in this photo?": {
    en: "What do you imagine you could smell or hear in this photo?",
    nl: "Wat denk je dat je zou kunnen ruiken of horen op deze foto?",
    ar: "ماذا بمخيلتك أن تشم أو تسمع في هذه الصورة؟",
  },
  "Can you describe the feeling in this photo, was it warm or cold?": {
    en: "Can you describe the feeling in this photo, was it warm or cold?",
    nl: "Kun je het gevoel op deze foto beschrijven, was het bijvoorbeeld warm of koud?",
    ar: "هل يمكنك وصف الاحساس فى هذه الصورة، هل كانت حارة ام باردة؟ هل هناك شيء ما أو شخص ما يذكرك بنفسك فى هذه الصورة؟",
  },
  "Is there something or someone that reminds you of yourself in this photo?": {
    en: "Is there something or someone that reminds you of yourself in this photo?",
    nl: "Is er iets of iemand die je aan jezelf doet denken op deze foto?",
    ar: "هل هناك شيء ما او شخص ما يذكرك بنفسك فى هذه الصورة؟",
  },
  "How do you feel when you see this photo?": {
    en: "How do you feel when you see this photo?",
    nl: "Hoe voel je je als je deze foto ziet?",
    ar: "كيف تشعر عندما ترى هذه الصورة؟",
  },
  "Is there any extra information you can add to this photo?": {
    en: "Is there any extra information you can add to this photo?",
    nl: "Is er extra informatie die je aan deze foto kunt toevoegen?",
    ar: "هل لديك معلومات آخرى يمكنك إضافتها إلى هذه الصورة؟",
  },
  "How to Tell a Herstory workshops are powerful": {
    en: "How to Tell a Herstory workshops are powerful sharing spaces where ordinary women are celebrated as extraordinary storytellers. Women bear witness to each other's experiences and are inspired to write a herstory. Hosted at various locations, the workshops connect women storytellers and open a creative and safe space for exploring untold narratives. At the core of the workshops are personal photo albums and our custom-built Herstory Tool that helps writers by giving various story prompts. The format of the workshop has two parts, the first is a sharing space where storytellers introduce themselves and their proposed herstory. Phase two is a writing phase using photographs and the Herstory Tool to craft the story (no technical skills are necessary). The workshops have a relaxed energy with music and many moments to eat and talk. All workshops are led by an artist with experience in storytelling and photography. The duration of the workshop is approximately 5 hours (including lunch) and is free of charge to participants.",
    nl: "How to Tell a Herstory-workshops bieden een inspirerende plek waar iedereen een buitengewone verhalenverteller is. Vrouwen delen elkaars verhalen en worden geïnspireerd om een ​​herstory te schrijven. De workshop vindt plaats op verschillende locaties, verbindt vrouwelijke verhalenvertellers en opent een creatieve en veilige ruimte voor het verkennen van onvertelde verhalen. De basis van de workshops zijn persoonlijke fotoalbums en onze op maat gemaakte Herstory Tool die verhalenvertellers helpt door verschillende aanwijzingen te geven in het proces van het schrijven. Het formaat van de workshop bestaat uit twee delen, de eerste fase is een introductieronde waarin vertellers zichzelf en hun voorgestelde Herstory voorstellen. Fase twee is een schrijffase waarbij gebruik wordt gemaakt van foto's en de Herstory Tool om het verhaal vorm te geven (er zijn geen technische vaardigheden vereist). De workshops hebben een ontspannen energie met muziek en veel momenten om te eten en te praten. Alle workshops worden geleid door een kunstenaar met ervaring in storytelling en fotografie. De duur van de workshop is ongeveer 5 uur (inclusief lunch) en is gratis voor deelnemers.",
    ar: "ورشة عمل ”كيف تحكي قصتها” هي مساحات مشاركة قوية حيث يتم الاحتفال بالنساء العاديات كرواة قصص غير عاديات. تشهد النساء على تجارب بعضهن البعض ويتم إلهامهن لكتابة “قصتها”. تستضيف ورش العمل في مواقع مختلفة ، وتربط بين راويات القصص وتفتح مساحة إبداعية وآمنة لاستكشاف قصص لا توصف. في قلب ورش العمل توجد ألبومات الصور الشخصية وأداة  ”قصتها”  المصممة خصيصًا والتي تساعد الكتاب من خلال تقديم مطالبات متنوعة للقصة. يتكون شكل ورشة العمل من جزأين ، الأول هو مساحة مشتركة حيث يقدم رواة القصص أنفسهم وقصتهم المقترحة. المرحلة الثانية هي مرحلة الكتابة باستخدام الصور وأداة ”قصتها” لصياغة القصة (لا يلزم وجود مهارات تقنية). تتمتع ورش العمل بطاقة مريحة مع الموسيقى والعديد من اللحظات لتناول الطعام والتحدث. يدير جميع ورش العمل فنان ذو خبرة في سرد ​​القصص والتصوير الفوتوغرافي. مدة ورشة العمل حوالي 5 ساعات (بما في ذلك الغداء) وهي مجانية للمشاركين.",
  },
  "Forthcoming workshops": {
    en: "Forthcoming workshops",
    nl: "Aanstaande workshops",
    ar: "",
  },
  "You are welcome to join us in May 2022 at De Voorkamer (Utrecht) for our next Herstory workshop.":
    {
      en: "You are welcome to join us in May 2022 at De Voorkamer (Utrecht) for our next Herstory workshop.",
      nl: "Je bent van harte welkom in mei 2022 bij De Voorkamer (Utrecht) voor onze volgende Herstory workshop.",
    },
  "Sign up to receive an email about the next workshop": {
    en: "Sign up to receive an email about the next workshop",
    nl: "Meld je aan om een e-mail te ontvangen over de volgende workshop",
    ar: "",
  },
  "Full name": {
    en: "Full name",
    nl: "Naam",
    ar: "",
  },
  Email: {
    en: "Email",
    nl: "E-mail",
    ar: "",
  },
  "By signing up": {
    en: "By signing up we will contact you shortly before May 2022",
    nl: "We nemen kort voor mei 2022 contact met je op",
    ar: "",
  },
  "The questions that the tool asks": {
    en: "The questions that the tool asks helps spark your curiosity and makes you look at your photos with different eyes.",
    nl: "De vragen die de tool stelt, wekken je nieuwsgierigheid en zorgen ervoor dat je met andere ogen naar je foto's kijkt.",
    ar: "”تساعد الأسئلة التي تطرحها الأداة على إثارة فضولك وتجعلك تنظر إلى صورك بعيون مختلفة.”",
  },
  "By looking for the answers": {
    en: "By looking for the answers, you dive further and further into history. Sometimes that search is painful, sad or there are details missing. Sometimes the stories are really beautiful and endearing. Or sometimes the stories were very mysterious. Sharing all those stories created a sense of recognition and connection.",
    nl: "Door de antwoorden te zoeken, duik je steeds verder de geschiedenis in. Soms is die zoektocht pijnlijk, verdrietig of ontbreken er details. Soms zijn de verhalen echt mooi en vertederend. Of soms waren de verhalen heel mysterieus. Door al die verhalen te delen, ontstond er een gevoel van herkenning en verbinding.",
    ar: "”من خلال البحث عن الإجابات ، تغوص أكثر وأكثر في التاريخ. أحيانًا يكون هذا البحث مؤلمًا أو حزينًا أو توجد تفاصيل مفقودة. في بعض الأحيان تكون القصص جميلة ومحببة حقًا. أو في بعض الأحيان كانت القصص غامضة للغاية. أدت مشاركة كل هذه القصص إلى خلق شعور بالاعتراف والتواصل ”.",
  },
  "Herstory workshop participant": {
    en: "Herstory workshop participant",
    nl: "Deelnemer Herstory workshop",
    ar: "-من أحد المشاركين في ورشة عمل قصتها",
  },
  "How to Tell a Herstory is a unique platform": {
    en: "How to Tell a Herstory is a unique platform for telling stories about women, to witness and celebrate their experiences.  The aim of the platform is to open a space for thinking and critical reflection on gender, migration and familial power structures through the lens of vernacular photography.",
    nl: "How to Tell a Herstory is een uniek platform om verhalen over vrouwen te delen en vieren. Het platform heeft als doel een ruimte te creëren voor kritische reflectie over gender, migratie en familiale machtsstructuren, door de lens van alledaagse fotografie.",
    ar: "”كيف تروي قصتها” هى منصة فريدة لرواية القصص عن النساء، لتشهد و تحتفل بخبراتهم. الهدف من المنصة هو إتاحة المساحة للتأمل و التفكير الحيوي عن الجنس و الهجرة و هيكليات القوة العائلية من خلال العدسات الفوتوغرافية المتداولة",
  },
  "A herstory highlights": {
    en: "A herstory highlights women’s perspectives in celebration of a more pluralistic view, in contrast to more singular, dominant histories.",
    nl: "Een herstory belicht het vrouwelijk perspectief, in tegenstelling tot de eenzijdige en dominante history, om een pluralistische kijk te bieden op onze voorgeschiedenis.",
    ar: "تقوم ”قصتها” بتسليط الضوء على وجهات النظر النسوية احتفالًا برؤية أكثر تعددية، على عكس المؤرخات الأكثر فردية و هيمنة",
  },
  "A herstory sees the world through the eyes of a woman": {
    en: "A herstory sees the world through the eyes of a woman.  A herstory can, for example, be about your great grandmother, about yourself, or someone close to you. At the centre of each herstory narrative are a few photographs of the subject of the story, selected by the storyteller and uploaded to our Herstory Tool. The tool analyses photos using machine learning and then prompts the storyteller into a narrative flow. The completed herstory is a visual essay with text, photos, annotations and voice notes.",
    nl: "Een herstory bekijkt de wereld door de ogen van een vrouw. Een herstory kan bijvoorbeeld over een overgrootmoeder gaan, jezelf, iemand die dicht bij je staat. Centraal in elke herstory staan foto's van het onderwerp, geselecteerd door de verteller en geüpload naar onze Herstory tool. De tool analyseert foto's met behulp van machine learning en geeft vervolgens de verhalenverteller wat inzichten over de foto’s en wat structuur in het verhaal. Het resultaat is een herstory in de vorm van een visuele essay met tekst, foto's, annotaties en gesproken notities.",
    ar: "”قصتها” ترى العالم من خلال عيون المرأة. يمكن أن تكون قصتها ، على سبيل المثال ، عن جدتك ، أو عن نفسك ، أو عن شخص قريب منك. يوجد في وسط كل ”قصتها” بعض الصور الفوتوغرافية لموضوع القصة ، اختارها الراوي وتم تحميلها على أداة ”قصتها”; الخاصة بنا. تقوم الأداة بتحليل الصور باستخدام التعلم الآلي ثم تدفع الراوي إلى التدفق السردي. ”قصتها” المكتملة عبارة عن مقال مرئي يحتوي على نصوص وصور وتعليقات توضيحية ورسائل صوتية.",
  },
  "Project creators": {
    en: "Project creators",
    nl: "De makers",
    ar: "مبدعين المشروع",
  },
  "How to Tell a Herstory was created by": {
    en: "How to Tell a Herstory was created by",
    nl: "How to Tell a Herstory is gemaakt door",
    ar: "تم انشاء ”كيف تروى قصتها” بواسطة ",
  },
  "an award winning research practice": {
    en: "an award winning research practice and creative studio. affect lab explores key cultural insights through design research, creative field work and immersive storytelling. Our social impact approach is guided by principles of care and empathy for local culture and community. This project was created by",
    nl: "een onderzoekspraktijk en creatieve studio. affect lab verkent belangrijke culturele inzichten door middel van ontwerpend onderzoek, creatief veldwerk en meeslepende verhalen. Onze benadering van sociale impact wordt geleid door principes van zorg en empathie voor cultuur en gemeenschap. Dit project is gemaakt door",
    ar: " ممارسة بحثية واستوديو إبداعي حائز على جوائز. يستكشف Affact lab الرؤى الثقافية الرئيسية من خلال أبحاث تصميمية والعمل الميداني الإبداعي ورواية القصص الغامرة. يسترشد نهج التأثير الاجتماعي لدينا بمبادئ الرعاية والتعاطف مع الثقافة والمجتمع المحلي. تم إنشاء هذا المشروع بواسطة",
  },
  and: {
    en: "and",
    nl: "en",
    ar: "و",
  },
  "in collaboration with technologist": {
    en: "in collaboration with technologist",
    nl: "in samenwerking met ontwikkelaar",
    ar: "بالتعاون مع التقني",
  },
  designer: {
    en: "designer",
    nl: "ontwerper",
    ar: "مصمم",
  },
  "workshop artist": {
    en: "workshop artist",
    nl: "workshopleider",
    ar: "فنان الورشة",
  },
  "and our dear friends at": {
    en: "and our dear friends at",
    nl: "en onze dierbare vrienden van",
    ar: "و أصدقاءنا الأعزاء فى",
  },
  "What is a Herstory?": {
    en: "What is a Herstory?",
    nl: "Wat is een Herstory?",
    ar: "ماذا تكون قصتها؟ ",
  },
  "Herstories are narratives about women": {
    en: "Herstories are narratives about women, to witness and celebrate their experiences.",
    nl: "Herstories zijn verhalen over vrouwen, om getuige te zijn van ervaringen en deze te vieren.",
    ar: "”قصصها” هن روايات عن النساء، لتشهد و تحتفل بخبراتهن الحياتية.",
  },
  "Can anyone write a Herstory?": {
    en: "Can anyone write a Herstory?",
    nl: "Mag iedereen een Herstory schrijven?",
    ar: "هل يمكن لاى شخص ان يكتب ”قصتها”؟",
  },
  "Yes, every storyteller is welcome": {
    en: "Yes, every storyteller is welcome",
    nl: "Ja, elke verteller is welkom.",
    ar: "نعم، كل راوي القصص مرحب به ",
  },
  "Can I write a Herstory about myself?": {
    en: "Can I write a Herstory about myself?",
    nl: "Mag ik een verhaal over mezelf schrijven?",
    ar: "هل يمكنني كتابة ”قصتها” عن نفسى؟",
  },
  "Yes. Herstories celebrate women": {
    en: "Yes. Herstories celebrate women, so you can be the subject of your own herstory. Your herstory can also be about another woman. ",
    nl: "Ja. Herstories vieren vrouwen, dus jij kunt het onderwerp zijn van je eigen Herstory. Je verhaal kan ook over een andere vrouw gaan.",
    ar: "نعم، قصصها تحتفل بالنساء، و بالتالى يمكنك ان تكونى موضوع قصتها الخاصة بك ",
  },
  "Do I need photographs to write a Herstory?": {
    en: "Do I need photographs to write a Herstory?",
    nl: "Heb ik foto’s nodig om een Herstory te schrijven? ",
    ar: "هل احتاج صور فوتوغرافية لكتابة ”قصتها”؟",
  },
  "Our Herstory Tool uses photographs": {
    en: "Our Herstory Tool uses photographs as a starting point to help writers tell their story, so ideally you need to have photographs to begin (even one photograph is fine!). However, if you have no photographs we invite you to take photographs of objects or places that remind you of the woman you are writing about.",
    nl: "Onze Herstory Tool gebruikt foto's als uitgangspunt om schrijvers te helpen hun verhaal te vertellen, dus idealiter heeft u foto's nodig om te beginnen (zelfs één foto is prima!). Als u echter geen foto's heeft, nodigen wij u uit om foto's te maken van objecten of plaatsen die u doen denken aan de vrouw waarover u schrijft.",
    ar: "تستخدم أداة ”قصتها” الصور كنقطة بداية لمساعدة الكتاب على سرد قصتهم ، لذلك من الناحية المثالية تحتاج إلى الحصول على صور لتبدأ (حتى صورة واحدة جيدة!). ومع ذلك ، إذا لم يكن لديك صور فوتوغرافية ، فإننا ندعوك لالتقاط صور لأشياء أو أماكن تذكرك بالمرأة التي تكتب عنها.",
  },
  "How do I share my Herstory with others?": {
    en: "How do I share my Herstory with others?",
    nl: "Hoe deel ik mijn Herstory met anderen?",
    ar: "كيف أشارك ”قصتها” مع الآخرين؟",
  },
  "You can share your Herstory in three ways:": {
    en: "You can share your Herstory in three ways:",
    nl: "U kunt uw Herstory op drie manieren delen:",
    ar: "يمكنك مشاركة ”قصتها” الخاصة بك بخلال ثلاث طرق:",
  },
  "Share your Herstory publicly": {
    en: "Share your Herstory publicly on the website: click on “visible to the public” in your privacy settings and your herstory will become visible on this website for everyone to read.",
    nl: "Maak uw Herstory openbaar op de website : Klik op “visible to the public” in uw privacy-instellingen en uw Herstory wordt hiermee zichtbaar gemaakt op deze website. Iedereen die deze website bezoekt kan dan uw verhaal lezen.",
    ar: "شارك ”قصتها” علنًا على الموقع الإلكتروني: انقر على ”مرئي للعامة” في إعدادات الخصوصية الخاصة بك وستصبح ”قصتها” مرئية على هذا الموقع ليقرأها الجميع.",
  },
  "Share your Herstory privately": {
    en: "Share your Herstory privately: Click on the “share” button and then click on the “link” button. Now you can send a private story link to a select few people, only the people with the link can view your story.",
    nl: "Deel uw Herstory in privé: Klik op de “share” knop en klik vervolgens op de knop “link”. Nu kunt u de link sturen naar een select aantal mensen, alleen de mensen met de link kunnen uw verhaal bekijken.",
    ar: "شارك ”قصتها” بشكل خاص: انقر على الزر ”مشاركة” ثم انقر على الزر ”ربط”. يمكنك الآن إرسال رابط قصة خاصة إلى عدد معيّن من الأشخاص المختارين ، ويمكن للأشخاص الذين لديهم الرابط فقط عرض ”قصتها”.",
  },
  "Share your Herstory via social media": {
    en: "Share your Herstory via social media:  Click on the “share” button and then click on one of the social media buttons and share your Herstory via Facebook, Whatsapp and/or Twitter.",
    nl: "Deel uw Herstory via social media: klik op de “share” knop, klik vervolgens op een van de social media knoppen en deel uw Herstory via Facebook, Whatsapp en/of Twitter.",
    ar: "شارك ”قصتها” عبر وسائل التواصل الاجتماعي: انقر على الزر ”مشاركة” ثم انقر على أحد أزرار وسائل التواصل الاجتماعي وشارك ”قصتها” عبر فيسبوك او واتساب او تويتر",
  },
  "Do I have to share my Herstory?": {
    en: "Do I have to share my Herstory?",
    nl: "Moet ik mijn Herstory delen?",
    ar: "هل يجب علي مشاركة ”قصتها” الخاصة بى؟",
  },
  "No, you can also save your Herstory as a draft or publish": {
    en: "No, you can also save your Herstory as a draft or publish it privately for your eyes only.",
    nl: "Nee, u kunt er ook voor kiezen om uw Herstory als concept (draft) op te slaan of te publiceren in privé. ",
    ar: "لا ، يمكنك أيضًا حفظ ”قصتها” الخاصة بك كمسودة أو نشرها بشكل خاص لعينيك فقط.",
  },
  "How do I share my Herstory privately?": {
    en: "How do I share my Herstory privately?",
    nl: "Hoe deel ik mijn Herstory in privé?",
    ar: "كيف أشارك قصتي بشكل خاص؟",
  },
  "When you save your draft or publish your Herstory privately": {
    en: "When you save your draft or publish your Herstory privately you can choose to share your story via a private link using the share button.",
    nl: "Wanneer je je concept opslaat of je Herstory privé publiceert, kun je ervoor kiezen om je verhaal te delen via een privé link met behulp van de “share” knop.",
    ar: "عندما تحفظ مسودتك أو تنشر ”قصتها” الخاصة بك بشكل خاص ، يمكنك اختيار مشاركة ”قصتها” عبر رابط خاص باستخدام زر المشاركة.",
  },
  "Are there any costs to use the Herstory tool?": {
    en: "Are there any costs to use the Herstory tool?",
    nl: "Zijn er kosten verbonden aan het gebruik van de Herstory-tool?",
    ar: "هل هناك أي تكاليف لاستخدام أداة ””قصتها””؟",
  },
  "No, the tool is entirely free to use.": {
    en: "No, the tool is entirely free to use.",
    nl: "Nee, de tool is geheel gratis te gebruiken.",
    ar: "لا ، الأداة مجانية تمامًا للاستخدام.”",
  },
  "Are my photos stored privately?": {
    en: "Are my photos stored privately?",
    nl: "Worden mijn foto’s privé opgeslagen?",
    ar: "هل صوري مخزنة بشكل خاص؟",
  },
  "Yes. Only you have access to your photos on this site.": {
    en: "Yes. Only you have access to your photos on this site. No third parties have access to your data. Your photos are only used to help you write a herstory.",
    nl: "Ja. Alleen jij hebt toegang tot je foto’s op deze website. Geen enkele derde partij heeft toegang tot uw gegevens. Uw foto’s worden alleen gebruikt om u te helpen een Herstory te schrijven.",
    ar: "نعم. فقط لديك حق الوصول إلى صورك على هذا الموقع. لا يمكن لأطراف ثالثة الوصول إلى البيانات الخاصة بك. تُستخدم صورك فقط لمساعدتك في كتابة قصة لها.",
  },
  "Where is this website hosted?": {
    en: "Where is this website hosted?",
    nl: "Waar wordt deze website gehost?",
    ar: "أين يتم استضافة هذا الموقع؟",
  },
  "The website is hosted on a cloud computing and storage platform": {
    en: "The website is hosted on a cloud computing and storage platform called Firebase.",
    nl: "De website wordt gehost op een cloud computing- en opslagplatform genaamd Firebase.",
    ar: "يتم استضافة موقع الويب على نظام أساسي للتخزين والحوسبة السحابية يسمى Firebase.",
  },
  "What is the Herstory Tool trained on?": {
    en: "What is the Herstory Tool trained on?",
    nl: "Waar is de Herstory Tool op getraind?",
    ar: "lorem",
  },
  "The Herstory Tool includes a custom-developed machine learning model": {
    en: "The Herstory Tool includes a custom-developed machine learning model currently being trained on family photographs – photos of weddings, birthday parties, a day at the beach, cooking a meal, doing chores in the house, a family gathering.",
    nl: "De Herstory Tool bevat een op maat ontwikkeld machine-learning model dat momenteel wordt getraind op familiefoto’s - foto’s van bruiloften, verjaardagsfeestjes, een dag op het strand, een maaltijd koken, klusjes in huis doen, een familiebijeenkomst.",
    ar: "ما الذي تدربت عليه أداة ”قصتها”؟",
  },
  "Update Profile": {
    en: "Update Profile",
    nl: "Update Profiel",
    ar: "تحديث الملف الشخصي",
  },
  "Display name": {
    en: "Display name",
    nl: "Display naam",
    ar: "الاسم المرئى ",
  },
  Password: {
    en: "Password",
    nl: "Wachtwoord",
    ar: "كلمة السر",
  },
  "Password confirmation": {
    en: "Password confirmation",
    nl: "Wachtwoord bevestigen",
    ar: "تأكيد كلمة السر",
  },
  Update: {
    en: "Update",
    nl: "Update",
    ar: "تحديث",
  },
  "Enter your email": {
    en: "Enter your email",
    nl: "Vul je email in",
    ar: "ادخل البريد الالكتروني الخاص بك",
  },
  "Enter your password": {
    en: "Enter your password",
    nl: "Vul je wachtwoord in",
    ar: "ادخل كلمة السر الخاصة بك",
  },
  "Forgot password?": {
    en: "Forgot password?",
    nl: "Wachtwoord vergeten?",
    ar: "هل نسيت كلمة السر؟",
  },
  "Need an account?": {
    en: "Need an account?",
    nl: "Account nodig?",
    ar: "هل ترغب إنشاء حساب؟",
  },
  "Sign up": {
    en: "Sign up",
    nl: "Meld je aan",
    ar: "اشترك",
  },
  "Password reset": {
    en: "Password reset",
    nl: "Wachtwoord opnieuw instellen",
    ar: "إعادة الإعداد لكلمة السر",
  },
  "Reset password": {
    en: "Reset password",
    nl: "Reset wachtwoord",
    ar: "اعد إعداد كلمة السر",
  },
  "Sign up to tell your Herstory": {
    en: "Sign up to tell your Herstory",
    nl: "Meld je aan om je Herstory te vertellen",
    ar: "اشترك لتروي “قصتها” الخاصة بك",
  },
  "First name": {
    en: "First name",
    nl: "Voornaam",
    ar: "الاسم الأول ",
  },
  "Password confirmation": {
    en: "Password confirmation",
    nl: "Wachtwoord bevestigen",
    ar: "هل لديك حساب؟",
  },
  "Sign up": {
    en: "Sign up",
    nl: "Aanmelden",
    ar: "اشترك",
  },
  "Already have an account?": {
    en: "Already have an account?",
    nl: "Heb je al een account?",
    ar: "هل لديك حساب؟",
  },
  "Password reset": {
    en: "Password reset",
    nl: "Wachtwoord opnieuw instellen",
    ar: "إعادة الإعداد لكلمة السر",
  },
  "Password reset": {
    en: "Password reset",
    nl: "Wachtwoord opnieuw instellen",
    ar: "إعادة الإعداد لكلمة السر",
  },
  "Curated Herstories": {
    en: "Curated Herstories",
    nl: "Selectie van Herstories",
    ar: "”قصصها” المختارة",
  },
  close: {
    en: "I see 2 people and they appear to be close",
    nl: "Ik zie 2 mensen en ze lijken dicht bij elkaar te staan",
    ar: "أرى ٢ أشخاص وهم يظهرون قريبين من بعضهم البعض .",
  },
  "very close": {
    en: "I see 2 people and they appear to be very close",
    nl: "Ik zie 2 mensen die dichtbij elkaar lijken te staan",
    ar: "أرى ٢ أشخاص وهم يظهرون قريبين جداً من بعضهم البعض .",
  },
  distant: {
    en: "I see 2 people and they appear to be distant from each other",
    nl: "Ik zie 2 mensen en ze lijken ver van elkaar af te staan",
    ar: "أرى ٢ أشخاص وهم يظهرون بعيدين من بعضهم البعض .",
  },
  "very distant": {
    en: "I see 2 people and they appear to be very distant from each other",
    nl: "Ik zie 2 mensen en ze lijken heel ver van elkaar af te staan",
    ar: "أرى ٢ أشخاص وهم يظهرون بعيدين جداً من بعضهم البعض .",
  },
  "Previous chapter": {
    en: "Previous chapter",
    nl: "Vorig hoofdstuk",
    ar: "الفصل السابق ",
  },
  "A story by": {
    en: "A story by",
    nl: "Een verhaal door",
    ar: "قصة بقلم",
  },
  "Written on": {
    en: "Written on",
    nl: "Geschreven op",
    ar: "كتبت عن ",
  },
  "Review story": {
    en: "Review story",
    nl: "Review verhaal",
    ar: "مراجعة القصة",
  },
  Chapter: {
    en: "Chapter",
    nl: "Hoofdstuk",
    ar: "فصل",
  },
  "Set cover photo": {
    en: "Set cover photo",
    nl: "Stel omslagfoto in",
    ar: "أضف صورة الغلاف",
  },
  Actions: {
    en: "Actions",
    nl: "Acties",
    ar: "إجراءات",
  },
  By: {
    en: "By",
    nl: "Door",
    ar: "بقلم ",
  },
  by: {
    en: "by",
    nl: "door",
    ar: "بقلم ",
  },
  happy: {
    en: "The mood in this photo seems happy. Tell me more about the emotions in this image",
    nl: "De stemming in deze foto lijkt gelukkig. Vertel me meer over de emoties in deze foto",
    ar: "يبدو المزاج سعيداً فى هذه الصورة، اخبرنى أكثر عن المشاعر الموجودة فى هذه الصورة.",
  },
  serious: {
    en: "The mood in this photo seems serious. Tell me more about the emotions in this image",
    nl: "De stemming in deze foto lijkt serieus. Vertel me meer over de emoties in deze foto",
    ar: "يبدو المزاج جاداً فى هذه الصورة، اخبرنى أكثر عن المشاعر الموجودة فى هذه الصورة.",
  },
  "Click and drag on the image above to add a note": {
    en: "Click and drag on the image above to add a note. Once you're done, click anywhere on the image to set the caption. Click the 'trash' icon to delete your annotation.",
    nl: "Klik en sleep op de afbeelding hierboven om een notitie toe te voegen. Als je klaar bent, klik dan ergens op de afbeelding om de notitie in te stellen. Klik op het 'prullenbak' icoon om uw notitie te verwijderen.",
    ar: "انقر ثم اسحب الصورة أعلاه لإضافة ملحوظة. حالما انتهيت، انقر على الصورة لإضافة عنوان انقر على أيقونة ”سلة المهملات” لحذف التعليقات التوضيحية.",
  },
  "Creative Commons": {
    en: "The Herstory workshop and website are licenced under Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International",
    nl: "De Herstory workshop en website zijn gelicenseerd onder Creative Commons  Attribution-NonCommercial-NoDerivatives 4.0 International",
    ar: "ورشة عمل و الموقع الالكترونى لقصتها مرخصين بموجب Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International (CC BY-NC-ND 4.0)",
  },
  "Workshop at De Voorkamer": {
    en: "Workshop at De Voorkamer",
    nl: "Workshop in De Voorkamer",
    ar: "ورشة عمل فى De Voorkamer",
  },
  "Photo by Sana Mulay": {
    en: "Photo by Sana Mulay",
    nl: "Foto gemaakt door Sana Mulay",
    ar: "صورة بعدسة Sana Mulay",
  },
  "Saturday 14 May 2022": {
    en: "Saturday 14 May 2022",
    nl: "Zaterdag 14 mei 2022",
    ar: "",
  },
  "Saturday 21 May 2022": {
    en: "Saturday 21 May 2022",
    nl: "Zaterdag 21 mei 2022",
    ar: "",
  },
  "Saturday 28 May 2022": {
    en: "Saturday 28 May 2022",
    nl: "Zaterdag 28 mei 2022",
    ar: "",
  },
  "fully booked": {
    en: "fully booked",
    nl: "volgeboekt",
    ar: "",
  },
  "Every workshop is free and takes place at De Voorkamer, Kanaalstraat 225 Utrecht between 11:00 - 17:00.":
    {
      en: "Every workshop is free and takes place at De Voorkamer, Kanaalstraat 225 Utrecht between 11:00 - 17:00.",
      nl: "Elke workshop is gratis en vindt plaats in De Voorkamer, Kanaalstraat 225 Utrecht tussen 11:00 - 17:00 uur.",
    },
};
