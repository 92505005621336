import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import LineIcon from "react-lineicons";
import * as ReactTranslated from "react-translated";
import translation from "./translation";
import SiteNav from "./SiteNav";

export default function Login(props) {
  const [language, setLangPreference] = useState("en");
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      history.push("/");
    } catch {
      setError("Failed to log in");
    }

    setLoading(false);
  }

  return (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content"
    >
      <div style={{ display: "none" }}>
        <SiteNav setLangPreference={setLangPreference} />
      </div>
      <Card className="Login">
        <Card.Body>
          <Container>
            <h2 className="text-center mb-4">
              <ReactTranslated.Translate text="Sign up to tell your Herstory" />
            </h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>
                  <ReactTranslated.Translate text="Email" />
                </Form.Label>
                <Form.Control
                  type="email"
                  ref={emailRef}
                  required
                  placeholder="Enter your Email"
                />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>
                  <ReactTranslated.Translate text="Password" />
                </Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  required
                  placeholder="Enter your Password"
                />
              </Form.Group>
              <Button
                disabled={loading}
                className="w-100 btn  main-btn"
                type="submit"
              >
                <ReactTranslated.Translate text="Login" />
              </Button>
            </Form>
            <div className="w-100 text-center mt-3">
              <Link to="/forgot-password">
                <ReactTranslated.Translate text="Forgot password?" />
              </Link>
            </div>
            <div className="w-100 text-center mt-2">
              <ReactTranslated.Translate text="Need an account?" />{" "}
              <Link to="/signup">
                <ReactTranslated.Translate text="Sign up" />
              </Link>
            </div>
          </Container>
        </Card.Body>
        <Link to="/">
          <button className="Backbutton">
            <LineIcon name="close" size="xs" />
          </button>
        </Link>
      </Card>
    </ReactTranslated.Provider>
  );
}
