import { Link } from "react-router-dom";

function TransLink({ pagelink, pagestring }) {
  return (
    <Link aria-current="page" to={pagelink}>
      {pagestring}
    </Link>
  );
}

export default TransLink;
