// @ts-check

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";

const SortableChapter = ({ chapter, dispatch, dndActiveId }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: chapter.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} {...attributes} style={{ ...style }} {...listeners}>
      <div className="sortableChapter">
        {dndActiveId !== chapter.id && <h2>{chapter.title}</h2>}
      </div>
    </div>
  );
};

export default SortableChapter;
