// @ts-check

import { updateProfile } from "firebase/auth";
import React, { useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { auth } from "../firebase";
import LineIcon from "react-lineicons";
import * as ReactTranslated from "react-translated";
import translation from "./translation";
import SiteNav from "./SiteNav";

export default function UpdateProfile(props) {
  const [language, setLangPreference] = useState("en");

  const displayNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);
  const { currentUser, updatePassword, updateEmail } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value));
    }
    if (displayNameRef.current.value !== currentUser.email) {
      const user = auth.currentUser;

      updateProfile(user, {
        displayName: displayNameRef.current.value,
      });
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value));
    }

    Promise.all(promises)
      .then(() => {
        history.push("/");
      })
      .catch(() => {
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content"
    >
      <div style={{ display: "none" }}>
        <SiteNav setLangPreference={setLangPreference} />
      </div>
      <Container style={{ marginTop: "80px" }}>
        <h2 className="text-center mb-4">
          <ReactTranslated.Translate text="Update Profile" />
        </h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group id="displayNameRef">
            <Form.Label>
              <ReactTranslated.Translate text="Display name" />
            </Form.Label>
            <Form.Control
              type="text"
              ref={displayNameRef}
              required
              defaultValue={currentUser.displayName}
            />
          </Form.Group>
          <Form.Group id="email">
            <Form.Label>
              <ReactTranslated.Translate text="Email" />
            </Form.Label>
            <Form.Control
              type="email"
              ref={emailRef}
              required
              defaultValue={currentUser.email}
            />
          </Form.Group>
          <Form.Group id="password">
            <Form.Label>
              <ReactTranslated.Translate text="Password" />
            </Form.Label>
            <Form.Control
              type="password"
              ref={passwordRef}
              placeholder="Leave blank to keep the same"
            />
          </Form.Group>
          <Form.Group id="password-confirm">
            <Form.Label>
              <ReactTranslated.Translate text="Password confirmation" />
            </Form.Label>
            <Form.Control
              type="password"
              ref={passwordConfirmRef}
              placeholder="Leave blank to keep the same"
            />
          </Form.Group>
          <Button
            disabled={loading}
            className="w-100 btn link-btn"
            type="submit"
            style={{ marginTop: "16px" }}
          >
            <ReactTranslated.Translate text="Update" />
          </Button>
        </Form>

        <Link to="/">
          <button className="Backbutton">
            <LineIcon name="close" size="xs" />
          </button>
        </Link>
      </Container>
    </ReactTranslated.Provider>
  );
}
