// @ts-check

import React, { forwardRef } from "react";
import LineIcon from "react-lineicons";
import { IMAGE } from ".";

// @ts-ignore
const DraggingImage = forwardRef(({ image, ...props }, ref) => {
  return (
    <div {...props} ref={ref}>
      <div className="uploadedImage">
        {image.dataURL && (
          <div style={{ marginRight: 8, position: "relative" }}>
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              src={image.dataURL}
              alt="Uploaded by user"
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default DraggingImage;
