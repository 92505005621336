import Container from "react-bootstrap/Container";
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Affectlab from "./affect-lab.png";
import CreativeIndustries from "./Creative_Industries_Fund_NL_logo.png";
import CreativeCommons from "./creative_commons.png";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import * as ReactTranslated from "react-translated";
import translation from "./translation";
import Br from "./Br";
import TransAnchor from "./TransAnchor";
import Cursor from "./cursor";

function AboutFooter(props) {
  const { currentUser } = useAuth();

  return (
    <footer className="Footer">
      <Container>
        <Row>
          <Col sm={3} className="footerSection">
            <h5>
              <ReactTranslated.Translate text="projectby" />
            </h5>
            <a href="https://affectlab.org" target="_blank">
              <img src={Affectlab} className="footerLogo" />
            </a>
          </Col>
          <Col sm={5} className="footerSection">
            <h5>
              <ReactTranslated.Translate text="supportedby" />
            </h5>
            <a href="https://affectlab.org" target="_blank">
              <img src={CreativeIndustries} className="footerLogo" />
            </a>
          </Col>
          <Col sm={4} className="footerSection">
            <img src={CreativeCommons} className="footerLogo" />
            <h6 style={{ marginTop: 0 }}>
              <ReactTranslated.Translate text="Creative Commons" /> (
              <a
                href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
                target="_blank"
              >
                CC BY-NC-ND 4.0
              </a>
              )
            </h6>
          </Col>
          <Col>
            <Link to="/privacy">Data and Privacy Policy</Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default AboutFooter;
