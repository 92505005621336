// @ts-check

import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import LineIcon from "react-lineicons";
import { Link } from "react-router-dom";
import { fetchPublicPublishedStories } from "../actions";
import { sortByIndex } from "../utils";
import Spinner from "./spinner";
import { getCoverImageURL } from "./StoryDetail";
import * as ReactTranslated from "react-translated";
import translation from "./translation";

const CuratedStories = (props) => {
  const [stories, setStories] = useState(null);
  useEffect(() => {
    fetchPublicPublishedStories().then((data) => {
      setStories(data);
    });
  }, []);
  return (
    <ReactTranslated.Provider language={props.lang} translation={translation}>
      <div className="Curatedstories">
        <Container>
          <div className="Storymeta">
            <h1>
              <ReactTranslated.Translate text="Curated Herstories" />
            </h1>
            <p>
              <ReactTranslated.Translate text="A Herstory is" /> <br></br>
              <Link to={`/herstories`}>
                <ReactTranslated.Translate text="View all Herstories" />
              </Link>{" "}
              <ReactTranslated.Translate text="created on this website" />.
            </p>
          </div>
        </Container>
        {stories ? (
          <Container className="Storiescontainer">
            {stories.length > 0 ? (
              stories.map((s) => {
                const imgSrc = s.chapters
                  ?.sort(sortByIndex)?.[0]
                  ?.images?.sort(sortByIndex)?.[0]?.url;
                const idmap = s.id;

                const coverImageURL = getCoverImageURL(s);
                return (
                  (idmap === "c43fc42d-faea-4adc-b50b-cef61d0a8075" ||
                    idmap === "7ec28c3b-51d6-4e9b-8562-6b7bace41821" ||
                    idmap === "47974087-7225-4490-a3cb-e84b77a1c903") && (
                    <div key={s.id}>
                      <Row className="Singlecurated">
                        <Col sm="6">
                          {coverImageURL && (
                            <Link to={`/story/${s.id}`}>
                              <div
                                className="Storythumb"
                                style={{
                                  backgroundImage: `url(${coverImageURL})`,
                                }}
                              />
                            </Link>
                          )}
                        </Col>
                        <Col sm="6" className="Excerpt">
                          <h2>{s.title}</h2>

                          {s.excerpt && <p>{s.excerpt}</p>}
                          <Link to={`/story/${s.id}`}>
                            <h5>
                              <ReactTranslated.Translate text="Read Herstory" />{" "}
                              <LineIcon name="arrow-right" />
                            </h5>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  )
                );
              })
            ) : (
              <Spinner />
            )}
          </Container>
        ) : (
          <Spinner />
        )}

        <div className="Storyfooter">
          <Link to={`/herstories`}>
            <button className="btn link-btn">
              <ReactTranslated.Translate text="Explore all Herstories" />
            </button>
          </Link>
        </div>
      </div>
    </ReactTranslated.Provider>
  );
};

export default CuratedStories;
