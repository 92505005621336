// @ts-check

import { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import LineIcon from "react-lineicons";
import { ReactPictureAnnotation } from "react-picture-annotation";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import ReactToPrint from "react-to-print";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import { fetchStoryById } from "../actions";
import { useAuth } from "../contexts/AuthContext";
import { getEditStoryURL, parseFirebaseDate, sortByIndex } from "../utils";
import DeleteStoryModal from "./delete-story-modal";
import Footer from "./footer";
import Spinner from "./spinner";
import * as ReactTranslated from "react-translated";
import translation from "./translation";
import SiteNav from "./SiteNav";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const getCoverImageURL = (story) => {
  // Get the cover image for the story
  if (!story) {
    return null;
  }

  if (story.coverImageURL) {
    return story.coverImageURL;
  } else {
    const images = story.chapters
      .sort(sortByIndex)
      .flatMap((chapter) =>
        chapter.images.sort(sortByIndex).map((image) => image)
      );

    const coverImage =
      images.find((image) => image.id === story.selectedCoverImageId) ??
      images[0];

    return coverImage.url;
  }
};

const IMAGE_WIDTH = 600;

const StoryDetail = (props) => {
  const [language, setLangPreference] = useState("en");
  const [sharestatus, setSharestatus] = useState(false);
  const [copied, setCopied] = useState(false);

  const siteurl = "https://howtotellaherstory-development.web.app";

  // @ts-ignore
  let { id } = useParams();

  const [story, setStory] = useState(null);
  const [printclass, setPrintclass] = useState(false);
  const { currentUser } = useAuth();

  const [deleteStory, setDeleteStory] = useState(null);

  // Fetch story data
  useEffect(() => {
    fetchStoryById(id).then((data) => {
      console.log(data);
      setStory(data);
    });
  }, [id]);

  const history = useHistory();
  const componentRef = useRef();

  const coverImageURL = getCoverImageURL(story);

  function addprintClass() {
    setPrintclass(true);
  }

  function removeprintClass() {
    setPrintclass(false);
  }

  var canEdit;

  if (currentUser) {
    canEdit = story?.userId === currentUser.uid;
  } else {
    canEdit = false;
  }

  return (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content"
    >
      <div style={{ display: "none" }}>
        <SiteNav setLangPreference={setLangPreference} />
      </div>
      <div className="Singlestory">
        {!canEdit ? (
          <Link to={`/herstories`}>
            <button className="Backbutton invertedbackButton">
              <LineIcon name="close" size="xs" />
            </button>
          </Link>
        ) : (
          <Link to={`/my-herstories`}>
            <button className="Backbutton invertedbackButton">
              <LineIcon name="close" size="xs" />
            </button>
          </Link>
        )}

        <ReactToPrint
          trigger={() => (
            <button className="printerBtn">
              <LineIcon name="printer" size="xs" />
            </button>
          )}
          content={() => componentRef.current}
          bodyClass={"toPrint"}
          onBeforeGetContent={() => {
            addprintClass();
          }}
          onAfterPrint={() => {
            removeprintClass();
          }}
        />

        {canEdit && (
          <>
            <Link to={getEditStoryURL(story.id)}>
              <button className="editDetailBtn">
                <LineIcon name="pencil-alt" size="xs" />
              </button>
            </Link>

            <button
              className="deleteDetailBtn"
              onClick={() => {
                setDeleteStory(story);
              }}
            >
              <LineIcon name="trash-can" size="xs" />
            </button>
          </>
        )}

        {story && (
          <div className="shareBtns">
            <div className="shareHeader">
              <button
                className="shareTitle"
                onClick={() => {
                  !sharestatus ? setSharestatus(true) : setSharestatus(false);
                  setCopied(false);
                }}
              >
                <ReactTranslated.Translate text="Share" />{" "}
                <LineIcon name="share" size="xs" />
              </button>
            </div>
            {sharestatus && (
              <div className="shareList">
                <WhatsappShareButton
                  url={siteurl + "/story/" + story.id}
                  windowHeight={800}
                  windowWidth={800}
                  title={
                    "Read '" +
                    story.title +
                    "' on How To Tell a Herstory, a digital toolkit to read photographic archives of female migrants."
                  }
                  separator=":: "
                  className="shareBtn whatsapp"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <TwitterShareButton
                  url={siteurl + "/story/" + story.id}
                  title={
                    "Read '" +
                    story.title +
                    "' on How To Tell a Herstory, a digital toolkit to read photographic archives of female migrants."
                  }
                  separator=":: "
                  className="shareBtn twitter"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>

                <FacebookShareButton
                  url={siteurl + "/story/" + story.id}
                  title={
                    "Read '" +
                    story.title +
                    "' on How To Tell a Herstory, a digital toolkit to read photographic archives of female migrants."
                  }
                  separator=":: "
                  className="shareBtn twitter"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <CopyToClipboard
                  text={siteurl + "/story/" + story.id}
                  onCopy={() => setCopied(true)}
                >
                  <button className="shareBtn copy">
                    <LineIcon name="link" size="xs" />
                  </button>
                </CopyToClipboard>
              </div>
            )}

            {copied && (
              <p>
                <ReactTranslated.Translate text="Link copied" />
              </p>
            )}
          </div>
        )}

        {story ? (
          <div ref={componentRef} className={`${printclass ? "printing" : ""}`}>
            <div className="storyHero">
              <h5 className="storyAuthor">
                <ReactTranslated.Translate text="A story by" /> {story.userName}
              </h5>
              <h1 className="storyTitle">{story.title}</h1>

              <div
                className="StoryHerothumb"
                style={{
                  backgroundImage: `url(${coverImageURL})`,
                }}
              >
                <div className="heroOverlay" />
                <p className="storyExcerpt">{story.excerpt}</p>
              </div>

              <div className="Storydate">
                <ReactTranslated.Translate text="Written on" />{" "}
                {parseFirebaseDate(story.savedAt).toLocaleDateString()}
              </div>
            </div>
            <Container>
              <div className="Slidenavigation">
                <div className="slidenav prev">
                  <LineIcon name="arrow-left" />{" "}
                  <ReactTranslated.Translate text="Previous chapter" />
                </div>
                <div className="slidenav next">
                  <ReactTranslated.Translate text="Next chapter" />{" "}
                  <LineIcon name="arrow-right" />
                </div>
              </div>

              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                simulateTouch={false}
                autoHeight={true}
                onSlideChange={() => window.scroll(0, 720)}
                navigation={{
                  prevEl: ".prev",
                  nextEl: ".next",
                }}
                className="Chapterswiper"
              >
                {story.chapters.sort(sortByIndex).map((chapter, index) => (
                  <SwiperSlide
                    data-hash="intimacy"
                    key={chapter.id}
                    className="singleChapter"
                  >
                    <h1 className="Chaptertitle">
                      <ReactTranslated.Translate text="Chapter" /> {index + 1}:
                      {" " + chapter.title}
                    </h1>
                    {chapter.images.sort(sortByIndex).map((image) => {
                      const activeImageHeight =
                        (image.naturalHeight / image.naturalWidth) *
                        IMAGE_WIDTH;
                      return (
                        <Row className="ImageRow" key={image.id}>
                          <Col sm="auto" className="ImageCol">
                            <div
                              style={{
                                width: IMAGE_WIDTH,
                                height: activeImageHeight,
                                pointerEvents: "none",
                              }}
                              className="ImageColchild"
                            >
                              {!image.annotationData ? (
                                <img
                                  src={image.url}
                                  alt="Uploaded by user"
                                  width={IMAGE_WIDTH}
                                />
                              ) : (
                                <ReactPictureAnnotation
                                  scrollSpeed={0}
                                  image={image.url}
                                  annotationData={image.annotationData}
                                  onSelect={() => {}}
                                  onChange={() => {}}
                                  width={IMAGE_WIDTH}
                                  height={activeImageHeight}
                                  annotationStyle={{
                                    padding: 5, // text padding
                                    fontSize: 13, // text font size
                                    fontColor: "#000000", // text font color
                                    fontBackground: "#00d300", // text background color
                                    fontFamily:
                                      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",

                                    /** stroke style **/
                                    lineWidth: 3, // stroke width
                                    shapeBackground:
                                      "hsla(120, 100%, 41%, 0.12)", // background color in the middle of the marker
                                    shapeStrokeStyle: "#00d300", // shape stroke color
                                    shadowBlur: 10, // stroke shadow blur
                                    shapeShadowStyle:
                                      "hsla(210, 9%, 31%, 0.35)", // shape shadow color

                                    /** transformer style **/
                                    transformerBackground: "#ffffff",
                                    transformerSize: 10,
                                  }}
                                />
                              )}
                            </div>
                          </Col>
                          {(image.text.length > 0 || image.audioURL) &&
                            image.text !== "<p><br></p>" && (
                              <Col
                                className={`TextCol ${
                                  image.audioURL ? "hasAudio" : ""
                                }`}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: image.text,
                                  }}
                                />
                                {image.audioURL && (
                                  <div className="audioPlayer">
                                    <AudioPlayer
                                      showJumpControls={false}
                                      src={image.audioURL}
                                      customAdditionalControls={[]}
                                      className="audioController"
                                    />
                                  </div>
                                )}
                              </Col>
                            )}
                        </Row>
                      );
                    })}
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="Slidenavigation">
                <div className="slidenav prev">
                  <LineIcon name="arrow-left" />{" "}
                  <ReactTranslated.Translate text="Previous chapter" />
                </div>
                <div className="slidenav next">
                  <ReactTranslated.Translate text="Next chapter" />{" "}
                  <LineIcon name="arrow-right" />
                </div>
              </div>
              <div className="Storyfooter">
                <Link to={`/herstories`}>
                  <button className="btn link-btn explore">
                    <ReactTranslated.Translate text="Explore all Herstories" />
                  </button>
                </Link>
              </div>
            </Container>
          </div>
        ) : (
          <Spinner />
        )}
        <Footer />
      </div>

      <DeleteStoryModal
        story={deleteStory}
        isOpen={!!deleteStory}
        close={() => {
          setDeleteStory(null);
        }}
        afterDelete={async () => {
          history.push("/my-herstories");
        }}
      />
    </ReactTranslated.Provider>
  );
};

export default StoryDetail;
