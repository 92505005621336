// @ts-check

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "react-quill/dist/quill.snow.css";
import "swiper/swiper.scss";
import DraggingChapter from "./dragging-chapter.js";
import { ACTION } from "./index.js";
import SortableChapter from "./sortable-chapter.js";

const ReorderChapters = ({ sortedChapters, dispatch }) => {
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const [dndActiveId, setDndActiveId] = useState(null);

  return (
    <Card className="single-step">
      <Card.Body>
        <Container>
          <div className="chapter-title">
            <h1>Reorder chapters</h1>
          </div>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={(event) => {
              const { active } = event;
              setDndActiveId(active.id);
            }}
            onDragEnd={(event) => {
              const { active, over } = event;

              if (active.id !== over.id) {
                const prevImageOrders = sortedChapters.map((i) => i.id);

                const activeIndex = prevImageOrders.indexOf(active.id);
                const overIndex = prevImageOrders.indexOf(over.id);

                const nextImageOrders = arrayMove(
                  prevImageOrders,
                  activeIndex,
                  overIndex
                );

                const nextIndices = {};
                for (let x = 0; x < nextImageOrders.length; x++) {
                  nextIndices[nextImageOrders[x]] = x;
                }

                dispatch({
                  type: ACTION.REORDER_CHAPTERS,
                  payload: nextIndices,
                });
              }
              setDndActiveId(null);
            }}
          >
            <SortableContext
              items={sortedChapters}
              strategy={rectSortingStrategy}
            >
              <div className="uploadedImages">
                {sortedChapters.map((chapter) => {
                  return (
                    <SortableChapter
                      key={chapter.id}
                      chapter={chapter}
                      dispatch={dispatch}
                      dndActiveId={dndActiveId}
                    />
                  );
                })}
              </div>
            </SortableContext>
            <DragOverlay>
              {dndActiveId ? (
                <DraggingChapter
                  // @ts-ignore
                  chapter={sortedChapters.find((i) => i.id === dndActiveId)}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default ReorderChapters;
