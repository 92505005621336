// @ts-check

import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import LineIcon from "react-lineicons";
import { Link, useHistory } from "react-router-dom";
import * as ReactTranslated from "react-translated";
import { fetchStoriesByUserId } from "../actions";
import { useAuth } from "../contexts/AuthContext";
import { getEditStoryURL } from "../utils";
import DeleteStoryModal from "./delete-story-modal";
import AboutFooter from "./aboutFooter";
import SiteNav from "./SiteNav";
import Spinner from "./spinner";
import { getCoverImageURL } from "./StoryDetail";
import translation from "./translation";
import Br from "./Br";
import TransAnchor from "./TransAnchor";
import About1 from "./About_page_1_1948.png";
import About2 from "./About_page_2_1949.png";
import About3 from "./About_page_3.png";

const About = (props) => {
  const [language, setLangPreference] = useState("en");
  const history = useHistory();

  const { currentUser } = useAuth();
  const userId = currentUser?.uid;
  const voorkamerlink = "https://devoorkamer.org/";
  const contactlink = "mailto:hello@affectlab.org";

  return (
    <ReactTranslated.Provider
      language={language}
      translation={translation}
      className="content"
    >
      <SiteNav setLangPreference={setLangPreference} />
      <Container className="Stories">
        <Row>
          <Col>
            <h1>
              <ReactTranslated.Translate text="About" />
            </h1>
            <p>
              <ReactTranslated.Translate text="How to Tell a Herstory is a unique platform" />
            </p>
          </Col>
        </Row>
        <Row className="article-section">
          <Col sm={6}>
            <h4>
              <ReactTranslated.Translate text="A herstory highlights" />
            </h4>
            <p></p>
            <p>
              <ReactTranslated.Translate text="A herstory sees the world through the eyes of a woman" />
            </p>
            <img src={About2} className="article-img" />
          </Col>
          <Col sm={6}>
            <img src={About3} className="article-img" />
            <h3>
              <ReactTranslated.Translate text="Project creators" />
            </h3>
            <p>
              <ReactTranslated.Translate text="How to Tell a Herstory was created by" />{" "}
              <a href="http://www.affectlab.org" target="_blank">
                affect lab
              </a>
              ,{" "}
              <ReactTranslated.Translate text="an award winning research practice" />{" "}
              <a href="https://www.nataliedixon.info/" target="_blank">
                Dr. Natalie Dixon
              </a>
              ,{" "}
              <a href="https://www.klasien.nl/about-1" target="_blank">
                Klasien van de Zandschulp
              </a>{" "}
              <ReactTranslated.Translate text="and" />{" "}
              <a
                href="https://www.linkedin.com/in/anneli-vanenburg-998697182/?originalSubdomain=nl"
                target="_blank"
              >
                Anneli Vanenburg
              </a>{" "}
              <ReactTranslated.Translate text="in collaboration with technologist" />{" "}
              <a href="https://babusinyoni.com" target="_blank">
                Babusi Nyoni
              </a>
              , <ReactTranslated.Translate text="designer" />{" "}
              <a href="https://www.lisavankleef.nl/" target="_blank">
                Lisa van Kleef
              </a>
              , <ReactTranslated.Translate text="workshop artist" />{" "}
              <a href="https://www.ebru-aydin.net/over-mij" target="_blank">
                Ebru Aydin
              </a>{" "}
              <ReactTranslated.Translate text="and our dear friends at" />{" "}
              <a href="https://devoorkamer.org/en/" target="_blank">
                de Voorkamer
              </a>
              .{" "}
            </p>
            <img src={About1} className="article-img" />
          </Col>
        </Row>
      </Container>
      <AboutFooter />
    </ReactTranslated.Provider>
  );
};

export default About;
